import {Component, Input, OnInit} from '@angular/core';
import {HrCompAndPtoInfo} from "../hrform.service";

@Component({
  selector: 'hrform-comp-pto-info',
  templateUrl: './hr-form-comp-pto-info.component.html',
  styleUrls: ['./hr-form-comp-pto-info.component.css']
})
export class HrFormCompPtoInfoComponent implements OnInit {
  @Input() compAndPtoInfo: HrCompAndPtoInfo;
  constructor() { }

  ngOnInit(): void {
  }

}

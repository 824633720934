<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">PLAN INFORMATION<br/><span style="font-size: 0.8rem;font-style: italic">Please be sure all benefit plans are reflected</span></div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Medical Insurance Provider Name &amp; Plan Number</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_medicalprovider" [checked]="planInfo.medicalProvider.notAvailable===true" (change)="onNAChange($event, planInfo.medicalProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_medicalprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.medicalProvider.notAvailable===true" [(ngModel)]="planInfo.medicalProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_medicalprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.medicalProvider.notAvailable===true" [(ngModel)]="planInfo.medicalProvider.planNumber">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Dental Insurance Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_dentalprovider" [checked]="planInfo.dentalProvider.notAvailable===true" (change)="onNAChange($event, planInfo.dentalProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_dentalprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.dentalProvider.notAvailable===true" [(ngModel)]="planInfo.dentalProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_dentalprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.dentalProvider.notAvailable===true" [(ngModel)]="planInfo.dentalProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Vision Insurance Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_dentalprovider" [checked]="planInfo.visionProvider.notAvailable===true" (change)="onNAChange($event, planInfo.visionProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_dentalprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.visionProvider.notAvailable===true" [(ngModel)]="planInfo.visionProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_dentalprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.visionProvider.notAvailable===true" [(ngModel)]="planInfo.visionProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Health Savings Account (HSA) Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_visionprovider" [checked]="planInfo.hsaProvider.notAvailable===true" (change)="onNAChange($event, planInfo.hsaProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_visionprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.hsaProvider.notAvailable===true" [(ngModel)]="planInfo.hsaProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_visionprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.hsaProvider.notAvailable===true" [(ngModel)]="planInfo.hsaProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Health Reimbursement Account (HRA) &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_visionprovider" [checked]="planInfo.hraProvider.notAvailable===true" (change)="onNAChange($event, planInfo.hraProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_visionprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.hraProvider.notAvailable===true" [(ngModel)]="planInfo.hraProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_visionprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.hraProvider.notAvailable===true" [(ngModel)]="planInfo.hraProvider.planNumber">
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-9">
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Flexible Spending Account (FSA) Provider &amp; Plan Number</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_fsaprovider" [checked]="planInfo.fsaProviderPlanNumber.notAvailable===true" (change)="onFSANAChange($event, planInfo.fsaProviderPlanNumber)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="planinfo_fsaproviderplannumber_provider" id="planinfo_fsaproviderplannumber_providerhc" value="HEALTH_CARE" [disabled]="planInfo.fsaProviderPlanNumber.notAvailable===true" [(ngModel)]="planInfo.fsaProviderPlanNumber.provider"/>
            <label class="form-check-label col-form-label-sm" for="planinfo_fsaproviderplannumber_providerhc">Health Care</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="planinfo_fsaproviderplannumber_provider" id="planinfo_fsaproviderplannumber_providerlim" value="LIMITED" [disabled]="planInfo.fsaProviderPlanNumber.notAvailable===true" [(ngModel)]="planInfo.fsaProviderPlanNumber.provider" />
            <label class="form-check-label col-form-label-sm" for="planinfo_fsaproviderplannumber_providerlim">Limited Purpose</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="planinfo_fsaproviderplannumber_provider" id="planinfo_fsaproviderplannumber_providerdep" value="DEPENDENT" [disabled]="planInfo.fsaProviderPlanNumber.notAvailable===true" [(ngModel)]="planInfo.fsaProviderPlanNumber.provider" />
            <label class="form-check-label col-form-label-sm" for="planinfo_fsaproviderplannumber_providerdep">Dependent Care</label>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_hsaprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.fsaProviderPlanNumber.notAvailable===true" [(ngModel)]="planInfo.fsaProviderPlanNumber.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Commuter Benefits (Parking/Transit) Provider</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="planinfo_commuterbenefitsprovider" [(ngModel)]="planInfo.commuterBenefitsProvider"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Basic Life Insurance/AD&amp;D Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_hsaprovider" [checked]="planInfo.bliADDProvider.notAvailable===true" (change)="onNAChange($event, planInfo.bliADDProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_hsaprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.bliADDProvider.notAvailable===true" [(ngModel)]="planInfo.bliADDProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_hsaprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.bliADDProvider.notAvailable===true" [(ngModel)]="planInfo.bliADDProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Voluntary Life/AD&amp;D Insurance Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_vlaprovider" [checked]="planInfo.vlaDDProvider.notAvailable===true" (change)="onNAChange($event, planInfo.vlaDDProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_vlaprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.vlaDDProvider.notAvailable===true" [(ngModel)]="planInfo.vlaDDProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_vlaprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.vlaDDProvider.notAvailable===true" [(ngModel)]="planInfo.vlaDDProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Short-Term Disability Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_hraprovider" [checked]="planInfo.shortTermDisabilityProvider.notAvailable===true" (change)="onNAChange($event, planInfo.shortTermDisabilityProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_hraprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.shortTermDisabilityProvider.notAvailable===true" [(ngModel)]="planInfo.shortTermDisabilityProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_hraprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.shortTermDisabilityProvider.notAvailable===true" [(ngModel)]="planInfo.shortTermDisabilityProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Long-Term Disability Provider &amp; Plan Number</label>
        <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_fsaproviderplannumber" [checked]="planInfo.longTermDisabilityProvider.notAvailable===true" (change)="onNAChange($event, planInfo.longTermDisabilityProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_fsaproviderplannumber_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.longTermDisabilityProvider.notAvailable===true" [(ngModel)]="planInfo.longTermDisabilityProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_fsaproviderplannumber_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.longTermDisabilityProvider.notAvailable===true" [(ngModel)]="planInfo.longTermDisabilityProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
  <div class="form-group row">
    <div class="col-sm-3">
      <label class="col-form-label-sm text-right">Other Insurance Provider &amp; Plan Number <!--<br *ngIf="" th:unless="${hrForm.getPlanInfo().getOtherInsuranceProvider().isNotAvailable()}"/><span th:text="${hrForm.getPlanInfo().getOtherInsuranceProviderNote()}"></span>--></label>
      <input type="text" name="planinfo_otherinsuranceprovider_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.otherInsuranceProvider.notAvailable===true" [(ngModel)]="planInfo.otherInsuranceProviderNote"/>
    </div>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio" style="width: 31px;">
                <input type="checkbox" name="planinfo_otherinsuranceprovider" [checked]="planInfo.otherInsuranceProvider.notAvailable===true" (change)="onNAChange($event, planInfo.otherInsuranceProvider)">
              </div>
              <div class="input-group-text input-group-second-text" style="width: 43px;">N/A</div>
            </div>
            <input type="text" name="planinfo_otherinsuranceprovider_name" class="form-control form-control-sm" placeholder="Name" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.otherInsuranceProvider.notAvailable===true" [(ngModel)]="planInfo.otherInsuranceProvider.name">
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-second-text" style="width: 74px;">Plan # </div>
            </div>
            <input type="text" name="planinfo_otherinsuranceprovider_plann" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planInfo.otherInsuranceProvider.notAvailable===true" [(ngModel)]="planInfo.otherInsuranceProvider.planNumber">
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">PLAN COST INFORMATION</div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you pay % or $ towards employee for insurance?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="plancostinfo_employeeinsurance" [(ngModel)]="planCostInfo.employeeInsurance"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you pay % or $ towards employee family for insurance?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="plancostinfo_employeefamilyinsurance" [(ngModel)]="planCostInfo.employeeFamilyInsurance"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Health</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="plancostinfo_employercontributiontohealth" [(ngModel)]="planCostInfo.employerContributionToHealth"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Dental</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="plancostinfo_employercontributiontodental" [(ngModel)]="planCostInfo.employerContributionToDental"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ contribution to Vison</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="plancostinfo_employercontributiontovision" [(ngModel)]="planCostInfo.employerContributionToVision"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you contribute employer funds to a HSA or HRA? If so, please list out how much.</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="checkbox" name="plancostinfo_employercontributiontohsa" [checked]="planCostInfo.employerContributionToHSA.n_a===true" (change)="onNAChange($event, planCostInfo.employerContributionToHSA)">
              </div>
              <div class="input-group-text input-group-second-text">N/A</div>
            </div>
            <input type="text" name="plancostinfo_employercontributiontohsa_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planCostInfo.employerContributionToHSA.n_a===true" [(ngModel)]="planCostInfo.employerContributionToHSA.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ Contribution to Life</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="checkbox" name="plancostinfo_employercontributiontolife" [checked]="planCostInfo.employerContributionToLife.n_a===true" (change)="onNAChange($event, planCostInfo.employerContributionToLife)">
              </div>
              <div class="input-group-text input-group-second-text">N/A</div>
            </div>
            <input type="text" name="plancostinfo_employercontributiontolife_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planCostInfo.employerContributionToLife.n_a===true" [(ngModel)]="planCostInfo.employerContributionToLife.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ Contribution to STD</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="checkbox" name="plancostinfo_employercontributiontostd" [checked]="planCostInfo.employerContributionToSTD.n_a===true" (change)="onNAChange($event, planCostInfo.employerContributionToSTD)">
              </div>
              <div class="input-group-text input-group-second-text">N/A</div>
            </div>
            <input type="text" name="plancostinfo_employercontributiontostd_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planCostInfo.employerContributionToSTD.n_a===true" [(ngModel)]="planCostInfo.employerContributionToSTD.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Employer % of $ Contribution to LTD</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="checkbox" name="plancostinfo_employercontributiontoltd" [checked]="planCostInfo.employerContributionToLTD.n_a===true" (change)="onNAChange($event, planCostInfo.employerContributionToLTD)">
              </div>
              <div class="input-group-text input-group-second-text">N/A</div>
            </div>
            <input type="text" name="plancostinfo_employercontributiontoltd_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planCostInfo.employerContributionToLTD.n_a===true" [(ngModel)]="planCostInfo.employerContributionToLTD.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Any other company paid benefits? Please list.</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="checkbox" name="plancostinfo_otherbenefits" [checked]="planCostInfo.otherBenefits.n_a===true" (change)="onNAChange($event, planCostInfo.otherBenefits)">
              </div>
              <div class="input-group-text input-group-second-text">N/A</div>
            </div>
            <input type="text" name="plancostinfo_otherbenefits_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="planCostInfo.otherBenefits.n_a===true" [(ngModel)]="planCostInfo.otherBenefits.value">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any special employee arrangements in relation to benefits we should be aware of (100% coverage, etc)?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="plancostinfo_specialarrangements" id="plancostinfo_specialarrangementsYes" value="YES" [(ngModel)]="planCostInfo.specialArrangements"/>
            <label class="form-check-label col-form-label-sm" for="plancostinfo_specialarrangementsYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="plancostinfo_specialarrangementsexplanation" placeholder="If yes, explain"  [(ngModel)]="planCostInfo.specialArrangementsExplanation" name="plancostinfo_specialarrangementsexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="plancostinfo_specialarrangements" id="plancostinfo_specialarrangementsNo" value="NO" [(ngModel)]="planCostInfo.specialArrangements" />
            <label class="form-check-label col-form-label-sm" for="plancostinfo_specialarrangementsNo">No</label>
          </div>
        </div>
      </div>

      <!--<input type="text" class="form-control form-control-sm" id="plancostinfo_specialarrangementsexplanation" [(ngModel)]="planCostInfo.specialArrangementsExplanation"/>-->
    </div>
  </div>
</div>

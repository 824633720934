import {Component, Input, OnInit} from '@angular/core';
import {HrRetirementPlanInfo, InsuranceType, RetirementPlanType} from "../hrform.service";

@Component({
  selector: 'hrform-retirement-plan-info',
  templateUrl: './hr-form-retirement-plan-info.component.html',
  styleUrls: ['./hr-form-retirement-plan-info.component.css']
})
export class HrFormRetirementPlanInfoComponent implements OnInit {
  @Input() retirementPlanInfo: HrRetirementPlanInfo;
  constructor() { }

  ngOnInit(): void {
  }

  public readonly RetirementPlanType = RetirementPlanType;

  isSelectedRetirementPlanType(key: string) {
    return this.retirementPlanInfo.planType.indexOf(key) > -1;
  }

  onSelectRetirementPlanType($event: Event, key: string) {
    const checkbox = $event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.retirementPlanInfo.planType.push(key);
    } else {
      this.retirementPlanInfo.planType = this.retirementPlanInfo.planType.filter(type => type !== key);
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {MarketingformService} from "../marketingform.service";

@Component({
  selector: 'marketing-form-internal',
  templateUrl: './marketing-form-internal.component.html',
  styleUrls: ['./marketing-form-internal.component.css']
})
export class MarketingFormInternalComponent implements OnInit {
  @Input() marketingForm: any;
  constructor() { }

  ngOnInit(): void {
  }

  onDiscountTypesCheckboxChange(event) {
    MarketingformService.onCheckBoxEvent(this.marketingForm.internalInfo.discountTypes, event);
  }
}

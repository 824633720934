import {Component, Input, OnInit} from '@angular/core';
import {HrPlanCostInfo, ValueOrNA} from "../hrform.service";

@Component({
  selector: 'hrform-plan-cost-info',
  templateUrl: './hr-form-plan-cost-info.component.html',
  styleUrls: ['./hr-form-plan-cost-info.component.css']
})
export class HrFormPlanCostInfoComponent implements OnInit {
  @Input() planCostInfo: HrPlanCostInfo;

  constructor() { }

  ngOnInit(): void {
  }

  onNAChange($event, valueOrNA: ValueOrNA) {
    if($event.target.checked) {
      valueOrNA.n_a = true;
    } else {
      valueOrNA.n_a = false;
    }
  }
}

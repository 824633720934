import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../../global-constants';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit {

  public onBoardingProjectId: string
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.onBoardingProjectId = sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID)
  }

  showDueDiligenceForm(){
    return ['/duediligenceform'];
  }

  showDeaForm(){
    return ['/deaform'];
  }

  showHrForm(){
    return ['/hrform'];
  }

  showNHRForm(){
    return ['/nhrform'];
  }

  showMarketingForm(){
    return ['/marketingform'];
  }

  showItForm(){
    return ['/itform'];
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'marketing-form-sponsor',
  templateUrl: './marketing-form-sponsor.component.html',
  styleUrls: ['./marketing-form-sponsor.component.css']
})
export class MarketingFormSponsorComponent implements OnInit {
  @Input() marketingForm: any;
  constructor() { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../global-constants';

@Injectable({
  providedIn: 'root'
})
export class MarketingformService {

    constructor(private http: HttpClient) { }

    saveForm(data) {
      return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/marketingform', data);
    }

    getProjectById(projectId:string) {
      return this.http.get(GlobalConstants.apiURL+'/api/onboarding/project/'+projectId);
    }

    getFormData(projectId:string, userName:string) {
      return this.http.get(GlobalConstants.apiURL+'/api/onboarding/form/marketingform/'+projectId+'/'+userName);
    }

    finish(data) {
      return this.http.post(GlobalConstants.apiURL+'/api/onboarding/form/editpermission', data);
    }

    public static onCheckBoxEvent(data:Array<any>, event) {
      if (event.target.checked) {
        data.push(event.target.value);
      } else {
        data.splice(data.indexOf(event.target.value), 1);
      }
    }
    public static validateDate(event, validationFields) {
      var validationSuccessful = true;
      //var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
      if(event.target.value!=null && event.target.value.length>0){
        validationSuccessful = GlobalConstants.REGEX_DATE_VALIDATION.test(event.target.value);
      }
      if(validationSuccessful){
        validationFields.splice(validationFields.indexOf(event.target.name), 1);
        event.target.style.backgroundColor = 'white';
      }else{
        validationFields.push(event.target.name);
        event.target.style.backgroundColor = GlobalConstants.ERROR_COLOR;
      }
    }

}

import {Component, Input, OnInit} from '@angular/core';
import {HrPayrollForm} from "../hrform.service";

@Component({
  selector: 'hrform-payroll-info',
  templateUrl: './hr-form-payroll-info.component.html',
  styleUrls: ['./hr-form-payroll-info.component.css']
})
export class HrFormPayrollInfoComponent implements OnInit {
  @Input() hrPayrollForm: HrPayrollForm;
  weekDays: {id,value, full}[] = [];

  @Input() shiftDifferentialRate: any;

  constructor() { }

  ngOnInit(): void {
    this.weekDays.push(
      {id: 'MONDAY', value: 'Mon', full: 'Monday'},
      {id: 'TUESDAY', value: 'Tue', full: 'Tuesday'},
      {id: 'WEDNESDAY', value: 'Wed', full: 'Wednesday'},
      {id: 'THURSDAY', value: 'Thu', full: 'Thursday'},
      {id: 'FRIDAY', value: 'Fri', full: 'Friday'},
      {id: 'SATURDAY', value: 'Sat', full: 'Saturday'},
      {id: 'SUNDAY', value: 'Sun', full: 'Sunday'},
    )
  }
  onShiftDifferentialApplyCheck($event: Event) {
    this.onCheckBoxEvent(this.hrPayrollForm.compPayInfo.shiftDifferentialApply, $event);
  }
  onShiftDifferentialApplyDayCheck($event: Event) {
    this.onCheckBoxEvent(this.hrPayrollForm.compPayInfo.shiftDifferentialApplyDayOfWeek, $event);
  }

  onHoursOfOperationChange($event, day: string) {
    if($event.target.checked) {
      console.log(`${day} : checked`)
      this.hrPayrollForm.compPayInfo.hoursOfOperation[day].enabled = true;
    } else {
      console.log(`${day} : unchecked`)
      this.hrPayrollForm.compPayInfo.hoursOfOperation[day].enabled = false;
    }
  }
  onCheckBoxEvent(data:Array<any>, event){
    if (event.target.checked) {
      data.push(event.target.value);
    } else {
      data.splice(data.indexOf(event.target.value), 1);
    }
  }

  // addEmployeeLeaveDetail(){
  //   this.hrForm.compInfo.empLeaveDetails.push({id:uuid(), name:'', leaveType:'',lastDayOfWork:'',returnDate:''});
  // }
  addHolidayToList(holiday: string|undefined){
    if(holiday === undefined) {
      holiday = "";
    }
    if (this.hrPayrollForm.compPayInfo.holidayList.indexOf(holiday)< 0) {
      this.hrPayrollForm.compPayInfo.holidayList.push(holiday);
    }
  }
  removeHolidayToList(holiday){
    this.hrPayrollForm.compPayInfo.holidayList.splice(this.hrPayrollForm.compPayInfo.holidayList.indexOf(holiday), 1);
  }
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'marketing-form-general',
  templateUrl: './marketing-form-general.component.html',
  styleUrls: ['./marketing-form-general.component.css']
})
export class MarketingFormGeneralComponent implements OnInit {

  @Input() marketingForm: any;

  constructor() { }

  ngOnInit(): void {
  }

}

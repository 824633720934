<div class="alert alert-primary" role="alert" style="text-align:center;">Referral Marketing</div>
<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If you are a General Practice, there is no need to complete this section. Select N/A and complete the questionnaire.</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" name="referralInfo_isGeneralPractice" id="referralInfo_isGeneralPractice" value="YES" [checked]="marketingForm.referralInfo.isGeneralPractice" (change)="onCheckGeneralNA($event)" />
          <label class="form-check-label col-form-label-sm" for="referralInfo_isGeneralPractice">N/A</label>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="!marketingForm.referralInfo.isGeneralPractice">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have a full or part-time referral manager on staff?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_hasReferralManager" id="referralInfo_hasReferralManagerYes" value="YES" [(ngModel)]="marketingForm.referralInfo.hasReferralManager"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_hasReferralManagerYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_hasReferralManager" id="referralInfo_hasReferralManagerNo" value="NO" [(ngModel)]="marketingForm.referralInfo.hasReferralManager" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_hasReferralManagerNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, what is their name and schedule?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control form-control-sm" placeholder="Name" name="referralinfo_referralmanagername" [(ngModel)]="marketingForm.referralInfo.referralManagerName" />
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm" placeholder="Schedule" name="referralinfo_referralmanagerschedule" [(ngModel)]="marketingForm.referralInfo.referralManagerSchedule" />
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How often do you visit rDVM hospitals?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_rDVMVisitFrequency" placeholder="" [(ngModel)]="marketingForm.referralInfo.rDVMVisitFrequency" name="referralInfo_rDVMVisitFrequency" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How many CE events events do you conduct a year?</label>
    <div class="col-sm-9">
      <input type="number" min="0" step="1" class="form-control form-control-sm" id="referralInfo_annualCEEvents" placeholder="" [(ngModel)]="marketingForm.referralInfo.annualCEEvents" name="referralInfo_annualCEEvents" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Select all types of interactive marketing events you host or attend</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_dinners" value="DINNERS" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('DINNERS') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_dinners">Dinners</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_round_tables" value="ROUND_TABLES" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('ROUND_TABLES') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_round_tables">Round Tables</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_full_day_ce" value="FULL_DAY_CE" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('FULL_DAY_CE') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_full_day_ce">Full-Day CE</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_half_day_ce" value="HALF_DAY_CE" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('HALF_DAY_CE') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_half_day_ce">Half-Day CE</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_vendor_sponsored_events" value="VENDOR_SPONSORED_EVENTS" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('VENDOR_SPONSORED_EVENTS') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_vendor_sponsored_events">Speaking at vendor-sponsored events</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_specialty_college_presentations" value="SPECIALTY_COLLEGE_PRESENTATIONS" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('SPECIALTY_COLLEGE_PRESENTATIONS') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_specialty_college_presentations">Presentations at specialty college meetings</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="referralinfo_marketingeventtypes_other" value="OTHER" [checked]="marketingForm.referralInfo.marketingEventTypes.indexOf('OTHER') > -1" (change)="onMarketingEventTypesCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="referralinfo_marketingeventtypes_other">Other&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="referralinfo_marketingeventtypes_otherDesc" placeholder="Please list"  name="referralinfo_marketingeventtypes_otherDesc" [(ngModel)]="marketingForm.referralInfo.marketingEventOtherDesc"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you track the number of referrals from referring veterinarians or, do you only track from referring hospitals?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_tracksReferralsFrom" id="referralInfo_tracksReferralsFromYes" value="FROM_REF_VETS" [(ngModel)]="marketingForm.referralInfo.tracksReferralsFrom"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_tracksReferralsFromYes">From referring veterinarians</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_tracksReferralsFrom" id="referralInfo_tracksReferralsFromNo" value="FROM_REF_HOSPITALS" [(ngModel)]="marketingForm.referralInfo.tracksReferralsFrom" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_tracksReferralsFromNo">From referring hospitals</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you track revenue that is associated with referral visits?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_tracksRevenueFromReferrals" id="referralInfo_tracksRevenueFromReferralsYes" value="YES" [(ngModel)]="marketingForm.referralInfo.tracksRevenueFromReferrals"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_tracksRevenueFromReferralsYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_tracksRevenueFromReferrals" id="referralInfo_tracksRevenueFromReferralsNo" value="NO" [(ngModel)]="marketingForm.referralInfo.tracksRevenueFromReferrals" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_tracksRevenueFromReferralsNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, do you tracking the first time only or is tracking ongoing?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_referralRevenueTrackingType" id="referralInfo_referralRevenueTrackingTypeYes" value="FIRST_TIME_ONLY" [(ngModel)]="marketingForm.referralInfo.referralRevenueTrackingType"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_referralRevenueTrackingTypeYes">First time only</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_referralRevenueTrackingType" id="referralInfo_referralRevenueTrackingTypeNo" value="ONGOING" [(ngModel)]="marketingForm.referralInfo.referralRevenueTrackingType" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_referralRevenueTrackingTypeNo">Ongoing</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you monitor the types of cases that are referred to understand your referral base?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_monitorsCaseTypes" placeholder="" [(ngModel)]="marketingForm.referralInfo.monitorsCaseTypes" name="referralInfo_monitorsCaseTypes" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you survey your referring veterinarians?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_surveysReferringVeterinarians" id="referralInfo_surveysReferringVeterinariansYes" value="YES" [(ngModel)]="marketingForm.referralInfo.surveysReferringVeterinarians"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_surveysReferringVeterinariansYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_surveysReferringVeterinarians" id="referralInfo_surveysReferringVeterinariansNo" value="NO" [(ngModel)]="marketingForm.referralInfo.surveysReferringVeterinarians" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_surveysReferringVeterinariansNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, how often do you survey the rDVM’s?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_surveyFrequency" placeholder="" [(ngModel)]="marketingForm.referralInfo.surveyFrequency" name="referralInfo_surveyFrequency" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What survey service do you use?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_surveyService" placeholder="" [(ngModel)]="marketingForm.referralInfo.surveyService" name="referralInfo_surveyService" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you require a referral form filled out and sent in for each case referred?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_requiresReferralForm" id="referralInfo_requiresReferralFormYes" value="YES" [(ngModel)]="marketingForm.referralInfo.requiresReferralForm"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_requiresReferralFormYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_requiresReferralForm" id="referralInfo_requiresReferralFormNo" value="NO" [(ngModel)]="marketingForm.referralInfo.requiresReferralForm" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_requiresReferralFormNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, is the form available on your website?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_referralFormAvailableOnWebsite" id="referralInfo_referralFormAvailableOnWebsiteYes" value="YES" [(ngModel)]="marketingForm.referralInfo.referralFormAvailableOnWebsite"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_referralFormAvailableOnWebsiteYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_referralFormAvailableOnWebsite" id="referralInfo_referralFormAvailableOnWebsiteNo" value="NO" [(ngModel)]="marketingForm.referralInfo.referralFormAvailableOnWebsite" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_referralFormAvailableOnWebsiteNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is the turnaround time to send finalized records and a referral letter to rDVM’s?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_recordTurnaroundTime" id="referralInfo_recordTurnaroundTimeh24" value="HOURS_24" [(ngModel)]="marketingForm.referralInfo.recordTurnaroundTime"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_recordTurnaroundTimeh24">24 hours</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_recordTurnaroundTime" id="referralInfo_recordTurnaroundTimeh48" value="HOURS_48" [(ngModel)]="marketingForm.referralInfo.recordTurnaroundTime" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_recordTurnaroundTimeh48">48 hours</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_recordTurnaroundTime" id="referralInfo_recordTurnaroundTimeh72" value="HOURS_72" [(ngModel)]="marketingForm.referralInfo.recordTurnaroundTime" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_recordTurnaroundTimeh72">72 hours</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_recordTurnaroundTime" id="referralInfo_recordTurnaroundTimeOther" value="OTHER" [(ngModel)]="marketingForm.referralInfo.recordTurnaroundTime" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_recordTurnaroundTimeOther">Other&nbsp;</label>
            <input type="text" class="form-control form-control-sm" placeholder="Please list" name="referralInfo_recordTurnaroundTimeOtherDesc" [(ngModel)]="marketingForm.referralInfo.otherTurnaroundTime" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How do you handle client complaints that appear online?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_onlineComplaintHandling" placeholder="" [(ngModel)]="marketingForm.referralInfo.onlineComplaintHandling" name="referralInfo_onlineComplaintHandling" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you work with the rDVM to help resolve the problem?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="referralInfo_worksWithRDVMToResolveComplaints" placeholder="" [(ngModel)]="marketingForm.referralInfo.worksWithRDVMToResolveComplaints" name="referralInfo_worksWithRDVMToResolveComplaints" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have referral marketing reporting capabilities in your PIMS (Practice Management Software)?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_hasReferralMarketingReportingInPIMS" id="referralInfo_hasReferralMarketingReportingInPIMSYes" value="YES" [(ngModel)]="marketingForm.referralInfo.hasReferralMarketingReportingInPIMS"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_hasReferralMarketingReportingInPIMSYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_hasReferralMarketingReportingInPIMS" id="referralInfo_hasReferralMarketingReportingInPIMSNo" value="NO" [(ngModel)]="marketingForm.referralInfo.hasReferralMarketingReportingInPIMS" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_hasReferralMarketingReportingInPIMSNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you use a CRM or third-party platform for referral tracking?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_usesCRMForReferralTracking" id="referralInfo_usesCRMForReferralTrackingYes" value="YES" [(ngModel)]="marketingForm.referralInfo.usesCRMForReferralTracking"/>
            <label class="form-check-label col-form-label-sm" for="referralInfo_usesCRMForReferralTrackingYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="referralInfo_usesCRMForReferralTracking" id="referralInfo_usesCRMForReferralTrackingNo" value="NO" [(ngModel)]="marketingForm.referralInfo.usesCRMForReferralTracking" />
            <label class="form-check-label col-form-label-sm" for="referralInfo_usesCRMForReferralTrackingNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, please provide name and information.</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <input type="text" class="form-control form-control-sm" placeholder="Name" name="referralinfo_crmName" [(ngModel)]="marketingForm.referralInfo.crmName" />
        </div>
        <div class="col">
          <input type="text" class="form-control form-control-sm" placeholder="Information" name="referralinfo_crmInformation" [(ngModel)]="marketingForm.referralInfo.crmInformation" />
        </div>
      </div>
    </div>
  </div>
</ng-container>



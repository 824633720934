import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from './auth.service';
import {GlobalConstants} from '../global-constants';
import {MsAuthenticationService} from "./ms-auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  userrole: string;
  errorMessage = 'Invalid Credentials';
  successMessage: string;
  invalidLogin = false;
  loginSuccess = false;
  authResult: any;
  otherUsersLogin = false;
  currentYear = new Date().getFullYear();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private msAuthenticationService: MsAuthenticationService) {
  }

  ngOnInit() {
  }

  login() {
    if (this.username != null && this.password != null) {
      console.log('Calling Authentication Service now');
      this.authenticationService.authenticationService(this.username, this.password).subscribe((result) => {
        this.onSuccessFullLogin(result);
      }, () => {
        this.invalidLogin = true;
        this.loginSuccess = false;
      });
    }
  }

  loginMs() {
    this.msAuthenticationService.login(false, result => {
      this.onSuccessFullLogin(result);
    }, error => {
      this.invalidLogin = true;
      this.loginSuccess = false;
    });
  }

  onSuccessFullLogin(result) {
    this.authResult = result;
    this.invalidLogin = false;
    this.loginSuccess = true;
    this.successMessage = 'Login Successful.';
    this.userrole = sessionStorage.getItem(GlobalConstants.AUTH_USER_ROLE);
    if (this.userrole === 'ADMIN') {
      this.router.navigate(['/listproject']);
    } else {
      this.router.navigate(['/duediligenceform'],
        {queryParams: {onBoardingProjectId: sessionStorage.getItem(GlobalConstants.AUTH_USER_PROJECT_ID)}});
    }
  }


}

<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">General Information</div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Hospital Practice Name</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="practiceName" placeholder="Enter practice name"  [(ngModel)]="generalInfo.practiceName" name="practiceName" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your benefit plan year?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_benefitplanyear" id="generalinfo_benefitplanyear0101" value="FROM_JAN" [(ngModel)]="generalInfo.benefitPlanYear"/>
            <label class="form-check-label col-form-label-sm" for="generalinfo_benefitplanyear0101">1/1 - 12/31</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_benefitplanyear" id="generalinfo_benefitplanyear0107" value="FROM_JULY" [(ngModel)]="generalInfo.benefitPlanYear" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_benefitplanyear0107">7/1 - 06/30</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_benefitplanyear" id="generalinfo_benefitplanyearOther" value="OTHER" [(ngModel)]="generalInfo.benefitPlanYear" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_benefitplanyearOther">Other</label>
            <input type="text" class="form-control form-control-sm" id="generalinfo_benefitplanyearNote" placeholder=""  [(ngModel)]="generalInfo.benefitPlanYearOther" name="generalinfo_benefitplanyearNote" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Types of Insurance Offered</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" *ngFor="let insuranceType of InsuranceType | keyvalue">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_{{insuranceType.key}}" [value]="insuranceType.key" [checked]="isSelectedInsuranceType(insuranceType.key)" (change)="onSelectInsuranceType($event, insuranceType.key)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_{{insuranceType.key}}">{{insuranceType.value}}</label>
          </div>
<!--          <div class="form-check form-check-inline">-->
<!--            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_scratchpay" value="SCRATCH_PAY" [checked]="itForm.pimsInfo.integrations.indexOf('SCRATCH_PAY') > -1" (change)="onLabIntegrationCheckboxChange($event)" />-->
<!--            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_scratchpay">Scratch Pay</label>-->
<!--          </div>-->
<!--          <div class="form-check form-check-inline">-->
<!--            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_wfargo" value="WELLS_FARGO" [checked]="itForm.pimsInfo.integrations.indexOf('WELLS_FARGO') > -1" (change)="onLabIntegrationCheckboxChange($event)" />-->
<!--            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_wfargo">Wells Fargo</label>-->
<!--          </div>-->
<!--          <div class="form-check form-check-inline">-->
<!--            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_petly" value="PETLY" [checked]="itForm.pimsInfo.integrations.indexOf('PETLY') > -1" (change)="onLabIntegrationCheckboxChange($event)" />-->
<!--            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_petly">Petly</label>-->
<!--          </div>-->
<!--          <div class="form-check form-check-inline">-->
<!--            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_other" value="OTHER" [checked]="itForm.pimsInfo.integrations.indexOf('OTHER') > -1" (change)="onLabIntegrationCheckboxChange($event)" />-->
<!--            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_other">Other&nbsp;</label>-->
<!--            <input type="text" class="form-control form-control-sm" id="integrationOtherDesc" placeholder="specify"  name="integrationOtherDesc" [(ngModel)]="itForm.pimsInfo.integrationOtherDesc"/>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Types of Life, Disability & Other Benefits Offered</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_bladd" value="BASIC_LIFE_AD_D" [checked]="generalInfo.lifeDisabilityBenefitTypes.indexOf('BASIC_LIFE_AD_D') > -1" (change)="onSelectLifeDisabilityBenefitType($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_bladd">Basic Life/AD&amp;D</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_lifedisabilitybenefittypes_vladd" value="VOLUNTARY_LIFE_AD_D" [checked]="generalInfo.lifeDisabilityBenefitTypes.indexOf('VOLUNTARY_LIFE_AD_D') > -1" (change)="onSelectLifeDisabilityBenefitType($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_lifedisabilitybenefittypes_vladd">Voluntary Life/AD&amp;D</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_lifedisabilitybenefittypes_std" value="SHORT_TERM_DISABILITY" [checked]="generalInfo.lifeDisabilityBenefitTypes.indexOf('SHORT_TERM_DISABILITY') > -1" (change)="onSelectLifeDisabilityBenefitType($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_lifedisabilitybenefittypes_std">Short-Term Disability</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_lifedisabilitybenefittypes_ltd" value="LONG_TERM_DISABILITY" [checked]="generalInfo.lifeDisabilityBenefitTypes.indexOf('LONG_TERM_DISABILITY') > -1" (change)="onSelectLifeDisabilityBenefitType($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_lifedisabilitybenefittypes_ltd">Long-Term Disability</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_lifedisabilitybenefittypes_other" value="OTHER" [checked]="generalInfo.lifeDisabilityBenefitTypes.indexOf('OTHER') > -1" (change)="onSelectLifeDisabilityBenefitType($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_lifedisabilitybenefittypes_other">Other&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="generalinfo_lifedisabilitybenefittypes_othernote" placeholder="specify"  name="generalinfo_lifedisabilitybenefittypes_othernote" [(ngModel)]="generalInfo.lifeDisabilityBenefitTypesOtherNote"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who is eligible for Benefits?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_eligibleforbenefits_ft" value="FULL_TIME" [checked]="generalInfo.eligibleForBenefits.indexOf('FULL_TIME') > -1" (change)="onEligibleForBenefitsCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_eligibleforbenefits_ft">Full Time</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_eligibleforbenefits_pt" value="PART_TIME" [checked]="generalInfo.eligibleForBenefits.indexOf('PART_TIME') > -1" (change)="onEligibleForBenefitsCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_eligibleforbenefits_pt">Part Time</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_eligibleforbenefits_other" value="OTHER" [checked]="generalInfo.eligibleForBenefits.indexOf('OTHER') > -1" (change)="onEligibleForBenefitsCheckboxChange($event)" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_eligibleforbenefitsothernote">Other&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="generalinfo_eligibleforbenefitsothernote" placeholder="specify"  name="generalinfo_eligibleforbenefitsothernote" [(ngModel)]="generalInfo.eligibleForBenefitsOtherNote"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How many hours/week do you need to work to be benefits eligible?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="generalinfo_hoursperweekforeligibility" [(ngModel)]="generalInfo.hoursPerWeekForEligibility"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is there a waiting period for benefits?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_haswaitingperiod" id="generalinfo_haswaitingperiodYes" value="YES" [(ngModel)]="generalInfo.hasWaitingPeriod"/>
            <label class="form-check-label col-form-label-sm" for="generalinfo_haswaitingperiodYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="generalinfo_haswaitingperiodexplanation" placeholder="If yes, explain" [disabled]="generalInfo.hasWaitingPeriod==='NO'"  [(ngModel)]="generalInfo.waitingPeriodExplanation" name="generalinfo_haswaitingperiodexplanation" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_haswaitingperiod" id="generalinfo_haswaitingperiodNo" value="NO" [(ngModel)]="generalInfo.hasWaitingPeriod" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_haswaitingperiodNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who is your Benefits Broker, Name &amp; Contact Info?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="generalinfo_benefitsbrokernameandcontact" [(ngModel)]="generalInfo.benefitsBrokerNameAndContact"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have any employees currently on CORBA?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_hasemployeesoncobra" id="generalinfo_hasemployeesoncobraYes" value="YES" [(ngModel)]="generalInfo.hasEmployeesOnCOBRA"/>
            <label class="form-check-label col-form-label-sm" for="generalinfo_hasemployeesoncobraYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="generalinfo_hasemployeesoncobra" id="generalinfo_hasemployeesoncobraNo" value="NO" [(ngModel)]="generalInfo.hasEmployeesOnCOBRA" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_hasemployeesoncobraNo">No</label>
          </div>
        </div>
      </div>
      <span style="font-style: italic;">If yes, please submit documentation stating who, effective date, end date and coverages enrolled.</span>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {HrGeneralInfo, InsuranceType} from "../hrform.service";

@Component({
  selector: 'hrform-general-info',
  templateUrl: './hr-form-general-info.component.html',
  styleUrls: ['./hr-form-general-info.component.css']
})
export class HrFormGeneralInfoComponent implements OnInit {

  @Input() generalInfo: HrGeneralInfo;
  constructor() { }

  ngOnInit(): void {
  }

  public readonly InsuranceType = InsuranceType;

  isSelectedInsuranceType(key: string) {
    return this.generalInfo.insuranceTypes.indexOf(InsuranceType[key]) > -1;
  }

  onSelectInsuranceType($event: Event, key: string) {
    const checkbox = $event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.generalInfo.insuranceTypes.push(key);
    } else {
      this.generalInfo.insuranceTypes = this.generalInfo.insuranceTypes.filter(type => type !== checkbox.value);
    }
  }

  onEligibleForBenefitsCheckboxChange($event: Event) {
    const checkbox = $event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.generalInfo.eligibleForBenefits.push(checkbox.value);
    } else {
      this.generalInfo.eligibleForBenefits = this.generalInfo.insuranceTypes.filter(type => type !== checkbox.value);
    }
  }

  onSelectLifeDisabilityBenefitType($event: Event) {
    const checkbox = $event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.generalInfo.lifeDisabilityBenefitTypes.push(checkbox.value);
    } else {
      this.generalInfo.lifeDisabilityBenefitTypes = this.generalInfo.lifeDisabilityBenefitTypes.filter(type => type !== checkbox.value);
    }
  }
}

<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">RETIREMENT PLAN INFORMATION<br/><span style="font-size: 0.8rem;font-style: italic">Note- it is the seller’s responsibility to close out their 401k plan.</span></div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What type of Retirement Plan do you provide?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_K401" [value]="'K401'" [checked]="isSelectedRetirementPlanType('K401')" (change)="onSelectRetirementPlanType($event, 'K401')" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_K401">401 K</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_SIMPLE_IRA" [value]="'SIMPLE_IRA'" [checked]="isSelectedRetirementPlanType('SIMPLE_IRA')" (change)="onSelectRetirementPlanType($event, 'SIMPLE_IRA')" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_SIMPLE_IRA">Simple IRA</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_ROTH_IRA" [value]="'ROTH_IRA'" [checked]="isSelectedRetirementPlanType('ROTH_IRA')" (change)="onSelectRetirementPlanType($event, 'ROTH_IRA')" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_ROTH_IRA">Roth IRA</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="checkbox" id="generalinfo_insurancetype_none" [value]="'NONE'" [checked]="isSelectedRetirementPlanType('NONE')" (change)="onSelectRetirementPlanType($event, 'NONE')" />
            <label class="form-check-label col-form-label-sm" for="generalinfo_insurancetype_none">None</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who is your Retirement Broker, Name &amp; Contact Info?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="retirementplaninfo_brokernameandcontact" [(ngModel)]="retirementPlanInfo.brokerNameAndContact"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide a match or give to the employee for their retirement?</label>
    <div class="col-sm-9">
<!--      <div class="form-row">-->
<!--        <div class="col">-->
<!--          <div class="form-check form-check-inline" style="width: 50%">-->
<!--            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_retirementplanoption" id="retirementplaninfo_retirementplanoptionMatch" value="YES" [(ngModel)]="retirementPlanInfo.retirementPlanOption"/>-->
<!--            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_retirementplanoptionMatch">Match  % or $</label>-->
<!--            <input type="text" class="form-control form-control-sm" id="retirementplaninfo_retirementplanoptionMatchText" [(ngModel)]="retirementPlanInfo.retirementPlanMatchValue"/>-->
<!--          </div>-->
<!--          -->
<!--          <div class="form-check form-check-inline" style="width: 50%">-->
<!--            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_retirementplanoption" id="retirementplaninfo_retirementplanoptionGive" value="NO" [(ngModel)]="retirementPlanInfo.retirementPlanOption" />-->
<!--            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_retirementplanoptionGive">Give % or $</label>-->
<!--            <input type="text" class="form-control form-control-sm" id="retirementplaninfo_retirementplanoptionGiveText" [(ngModel)]="retirementPlanInfo.retirementPlanGiveValue"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="radio" name="retirementplaninfo_retirementplanoption" id="retirementplaninfo_retirementplanoptionMatch" value="MATCH" [(ngModel)]="retirementPlanInfo.retirementPlanOption"/>
              </div>
              <div class="input-group-text input-group-second-text" style="width: 100px">Match % or $  </div>
            </div>
            <input type="text" class="form-control form-control-sm" placeholder="Amount" name="retirementplaninfo_retirementplanoptionMatch_amount" [(ngModel)]="retirementPlanInfo.retirementPlanMatchValue" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="radio" name="retirementplaninfo_retirementplanoption" id="retirementplaninfo_retirementplanoptionGive" value="GIVE" [(ngModel)]="retirementPlanInfo.retirementPlanOption"/>
              </div>
              <div class="input-group-text input-group-second-text" style="width: 100px">Give % or $   </div>
            </div>
            <input type="text" class="form-control form-control-sm" placeholder="Amount" name="retirementplaninfo_retirementplanoptionGive_amount" [(ngModel)]="retirementPlanInfo.retirementPlanGiveValue" />
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is the eligibility for the employer match?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="retirementplaninfo_eligibilitycriteriamatch" [(ngModel)]="retirementPlanInfo.eligibilityCriteriaMatch"/>
    </div>

  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If IRA is offered, what type of contributions do you offer?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="radio" name="iraContributionType" id="retirementplaninfo_iraContributionTypePre" value="PRE" [(ngModel)]="retirementPlanInfo.iraContributionType"/>
              </div>
              <div class="input-group-text input-group-second-text"  style="width: 115px">Pre Tax  % or $   </div>
            </div>
            <input type="text" class="form-control form-control-sm" placeholder="Amount" name="retirementplaninfo_iraContributionTypePre_amount" [(ngModel)]="retirementPlanInfo.iraContributionTypePreValue" />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <div class="input-group input-group-sm mb-2">
            <div class="input-group-prepend">
              <div class="input-group-text input-group-text-radio">
                <input type="radio" name="iraContributionType" id="retirementplaninfo_iraContributionTypePost" value="POST" [(ngModel)]="retirementPlanInfo.iraContributionType"/>
              </div>
              <div class="input-group-text input-group-second-text" style="width: 115px">Post Tax  % or $  </div>
            </div>
            <input type="text" class="form-control form-control-sm" placeholder="Amount" name="retirementplaninfo_iraContributionTypePost_amount" [(ngModel)]="retirementPlanInfo.iraContributionTypePostValue" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is the eligibility for the 401k plan?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="retirementplaninfo_eligibilitycriteria401k" [(ngModel)]="retirementPlanInfo.eligibilityCriteria401k"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have vesting schedule? If yes, please explain.</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width: 70%">
            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_vestingscheduleexplanation" id="retirementplaninfo_vestingscheduleexplanationYes" value="YES" [(ngModel)]="retirementPlanInfo.hasVestingSchedule"/>
            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_vestingscheduleexplanationYes">Yes</label>
            <input type="text" class="form-control form-control-sm" id="retirementplaninfo_vestingscheduleexplanationYesText" [(ngModel)]="retirementPlanInfo.vestingScheduleExplanation"/>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_vestingscheduleexplanation" id="retirementplaninfo_vestingscheduleexplanationNo" value="NO" [(ngModel)]="retirementPlanInfo.hasVestingSchedule" />
            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_vestingscheduleexplanationNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do any employees have active loans taken out against their retirement plan?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width: 70%">
            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_activeloans" id="retirementplaninfo_activeloansYes" value="YES" [(ngModel)]="retirementPlanInfo.activeLoans"/>
            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_activeloansYes">Yes</label>
            <input type="text" class="form-control form-control-sm" id="retirementplaninfo_activeloansYesText" [(ngModel)]="retirementPlanInfo.activeLoansExplanation"/>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="retirementplaninfo_activeloans" id="retirementplaninfo_activeloansNo" value="NO" [(ngModel)]="retirementPlanInfo.activeLoans" />
            <label class="form-check-label col-form-label-sm" for="retirementplaninfo_activeloansNo">No</label>
          </div>
        </div>
      </div>
      <span style="font-style: italic;">If yes, please provide information on who, start date, end date and balance remaining. You will need to work with the employees on their options.</span>
    </div>
  </div>

</div>

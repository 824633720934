
<div class="alert alert-primary" role="alert" style="text-align:center;">Sponsorship</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you sponsor any local organizations?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="sponsorLocalOrg" id="sponsorLocalOrgYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorLocalOrg"/>
          <label class="form-check-label col-form-label-sm" for="sponsorLocalOrgYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="sponsorLocalOrg" id="sponsorLocalOrgNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorLocalOrg" />
          <label class="form-check-label col-form-label-sm" for="sponsorLocalOrgNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, please list for each sponsorship who, when, and annual cost?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="sponsorshipDetails" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.sponsorshipDetails" name="sponsorshipDetails" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have signage at field or rink?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveFieldRinkSignage" id="haveFieldRinkSignageYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.haveFieldRinkSignage"/>
          <label class="form-check-label col-form-label-sm" for="haveFieldRinkSignageYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveFieldRinkSignage" id="haveFieldRinkSignageNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.haveFieldRinkSignage" />
          <label class="form-check-label col-form-label-sm" for="haveFieldRinkSignageNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, what are terms and duration?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="termsDurations" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.termsDurations" name="termsDurations"/>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you donate to local charity events?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="donateToCharity" id="donateToCharityYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.donateToCharity"/>
          <label class="form-check-label col-form-label-sm" for="donateToCharityYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="donateToCharity" id="donateToCharityNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.donateToCharity"/>
          <label class="form-check-label col-form-label-sm" for="donateToCharityNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, are any recurring annually?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="recurringCharityYearly" id="recurringCharityYearlyYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.recurringCharityYearly"/>
          <label class="form-check-label col-form-label-sm" for="recurringCharityYearlyYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="recurringCharityYearly" id="recurringCharityYearlyNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.recurringCharityYearly"/>
          <label class="form-check-label col-form-label-sm" for="recurringCharityYearlyNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If recurring, what are they?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="charityList" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.charityList" name="charityList"/>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">What is your annual budget for charitable giving, if none what is the criteria or throught process for charitable giving?</label>
  <div class="col-sm-4">
    <input type="number" class="form-control form-control-sm" id="annualCharityBudget" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.annualCharityBudget" name="annualCharityBudget"/>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Does practice participate in parades and events?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="participateInEvents" id="participateInEventsYes" value="YES" [(ngModel)]="marketingForm.sponsorshipInfo.participateInEvents"/>
          <label class="form-check-label col-form-label-sm" for="participateInEventsYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="participateInEvents" id="participateInEventsNo" value="NO" [(ngModel)]="marketingForm.sponsorshipInfo.participateInEvents"/>
          <label class="form-check-label col-form-label-sm" for="participateInEventsNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, what events, where and when?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="participatingEventList" placeholder="" [(ngModel)]="marketingForm.sponsorshipInfo.participatingEventList" name="participatingEventList"/>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {GlobalConstants} from "../../../global-constants";
import {MarketingformService} from "../marketingform.service";

@Component({
  selector: 'marketing-form-website',
  templateUrl: './marketing-form-website.component.html',
  styleUrls: ['./marketing-form-website.component.css']
})
export class MarketingFormWebsiteComponent implements OnInit {
  @Input() marketingForm: any;
  @Input() validationFields: any;
  errorMessageInvalidDate = GlobalConstants.ERROR_MSG_INVALID_DATE;

  constructor() { }

  ngOnInit(): void {
  }

  public validateDate(event) {
    MarketingformService.validateDate(event, this.validationFields);
  }
}


<div class="alert alert-primary" role="alert" style="text-align:center;">Website</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">How is traffic directed to your website currently?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="businessDiscoveryDesc" placeholder="" [(ngModel)]="marketingForm.websiteInfo.businessDiscoveryDesc" name="businessDiscoveryDesc" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Does your practice show up in web searches?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="practiceShowOnSearch" id="practiceShowOnSearchYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.practiceShowOnSearch"/>
          <label class="form-check-label col-form-label-sm" for="practiceShowOnSearchYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="practiceShowOnSearch" id="practiceShowOnSearchNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.practiceShowOnSearch" />
          <label class="form-check-label col-form-label-sm" for="practiceShowOnSearchNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Is your website optimized for search engines?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="siteOptimized" id="siteOptimizedYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.siteOptimized"/>
          <label class="form-check-label col-form-label-sm" for="siteOptimizedYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="siteOptimized" id="siteOptimizedNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.siteOptimized" />
          <label class="form-check-label col-form-label-sm" for="siteOptimizedNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, which company optimizes?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="adSearchCompany" placeholder="" [(ngModel)]="marketingForm.websiteInfo.adSearchCompany" name="adSearchCompany" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Does your website include "conversion" forms where visitors can leave email address in exchange for content like newsletter?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="availableConversionForm" id="availableConversionFormYes" value="YES" [(ngModel)]="marketingForm.websiteInfo.availableConversionForm"/>
          <label class="form-check-label col-form-label-sm" for="availableConversionFormYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="availableConversionForm" id="availableConversionFormNo" value="NO" [(ngModel)]="marketingForm.websiteInfo.availableConversionForm" />
          <label class="form-check-label col-form-label-sm" for="availableConversionFormNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Who hosts your website?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="webHostingContact" placeholder="" [(ngModel)]="marketingForm.websiteInfo.webHostingContact" name="webHostingContact" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Who updates your website?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="siteUpdateContact" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteUpdateContact" name="siteUpdateContact" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">How often is it updated?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="siteUpdateFrequency" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteUpdateFrequency" name="siteUpdateFrequency" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">When was it last updated?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="siteLastUpdatedOn" placeholder="MM/DD/YYYY" [(ngModel)]="marketingForm.websiteInfo.siteLastUpdatedOn" name="siteLastUpdatedOn" (blur)="validateDate($event)"/>
    <div class="alert alert-warning" *ngIf="validationFields.indexOf('siteLastUpdatedOn')> -1">{{errorMessageInvalidDate}}</div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">When was the website built?</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="siteCreatedOn" placeholder="" [(ngModel)]="marketingForm.websiteInfo.siteCreatedOn" name="siteCreatedOn" (blur)="validateDate($event)"/>
    <div class="alert alert-warning" *ngIf="validationFields.indexOf('siteCreatedOn')> -1">{{errorMessageInvalidDate}}</div>
  </div>
</div>

<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">Compensation/Payroll Information</div>
  <div>
    <label class="col-sm-12 col-form-label row">Practice manager’s name and any other employees who need access to edit and
      approve timecards.</label>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right" for="practiceManager">Practice manager</label>
      <div class="col-sm-9">
        <input type="text" class="form-control form-control-sm" id="practiceManager" placeholder="Practice manager's name"  [(ngModel)]="hrPayrollForm.compPayInfo.practiceManager" name="practiceManager" />
      </div>
      <label class="col-sm-3 col-form-label-sm text-right" for="additionalAuthorizedApprovers">Additional Authorized Approvers</label>
      <div class="col-sm-9">
        <input type="text" class="form-control form-control-sm" id="additionalAuthorizedApprovers" placeholder="Enter names comma separated"  [(ngModel)]="hrPayrollForm.compPayInfo.additionalAuthorizedApprovers" name="additionalAuthorizedApprovers" />
      </div>
    </div>
  </div>
  <div>
    <label class="col-sm-12 col-form-label row">What are your hours of operation? If not open, please put Closed in the box.</label>
    <div class="form-group row" *ngFor="let hoursOfOperation of weekDays; let x=index">
      <label class="col-sm-3 col-form-label-sm text-right" for="hoursOfOperation_{{hoursOfOperation.value}}">{{hoursOfOperation.full}}</label>
      <div class="col-sm-9">
        <div class="form-row">
          <div class="col">
            <div class="input-group input-group-sm mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text input-group-text-radio">
                  <input type="checkbox" name="hoursOfOperation_{{hoursOfOperation.value}}" [checked]="hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled===true" (change)="onHoursOfOperationChange($event, hoursOfOperation.full.toLowerCase())">
                </div>
                <div class="input-group-text input-group-second-text">{{hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled ? 'Open' : 'Closed'}} </div>
              </div>
              <input type="text" name="hoursOfOperation_{{hoursOfOperation.value}}_note" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1" [disabled]="!hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].enabled" [(ngModel)]="hrPayrollForm.compPayInfo.hoursOfOperation[hoursOfOperation.full.toLowerCase()].note">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do employees work overnight shifts (third shift)?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="overnightShifts" id="overnightShiftsYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.overnightShifts"/>
            <label class="form-check-label col-form-label-sm" for="overnightShiftsYes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="overnightShifts" id="overnightShiftsNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.overnightShifts" />
            <label class="form-check-label col-form-label-sm" for="overnightShiftsNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <label class="col-sm-12 col-form-label text-left row">A shift differential is an incentive offered to employees in exchange for working
      harder to schedule shifts.</label>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right">Do you offer shift differential pay?</label>
      <div class="col-sm-9">
        <div class="form-row">
          <div class="col">
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialPay" id="shiftDifferentialPayYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialPay"/>
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialPayYes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialPay" id="shiftDifferentialPayNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialPay" />
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialPayNo">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right">If yes, who does the shift differential apply to</label>
      <div class="col-sm-9">
        <div class="form-row">
          <div class="col">
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHFTE" value="HOURLY_FT_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_FT_EMPLOYEES') > 0" (change)="onShiftDifferentialApplyCheck($event);"/>
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHFTE">Hourly FT employees</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHPTE" value="HOURLY_PT_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_PT_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHPTE">Hourly PT employees</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplyHPDE" value="HOURLY_PER_DIEM_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('HOURLY_PER_DIEM_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplyHPDE">Hourly Per Diem employees</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApply" id="shiftDifferentialApplySE" value="SALARIED_EMPLOYEES" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApply.indexOf('SALARIED_EMPLOYEES') > 0"  (change)="onShiftDifferentialApplyCheck($event);"/>
              <label class="form-check-label col-form-label-sm" for="shiftDifferentialApplySE">Salaried employees</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right">What days of the week does the shift differential apply to</label>
      <div class="col-sm-9">
        <div class="form-row">
          <div class="col">
            <div class="form-check form-check-inline"  *ngFor="let applyDayOfWeek of weekDays; let x=index">
              <input class="form-check-input form-control-sm" type="checkbox" name="shiftDifferentialApplyDayOfWeek" [id]="'shiftDifferentialApplyDayOfWeek-'+applyDayOfWeek.id" [value]="applyDayOfWeek.id" [checked]="hrPayrollForm.compPayInfo.shiftDifferentialApplyDayOfWeek.indexOf(applyDayOfWeek.id) > 0" (change)="onShiftDifferentialApplyDayCheck($event);"/>
              <label class="form-check-label col-form-label-sm" [for]="'shiftDifferentialApplyDayOfWeek-'+applyDayOfWeek.id">{{applyDayOfWeek.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right">What is the shift differential rate?</label>
      <div class="col-sm-9">
        <div class="form-row">
          <div class="col">
            <div class="input-group input-group-sm mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text input-group-text-radio">
                  <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_ADD" value="HOURLY_ADD" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                </div>
                <div class="input-group-text input-group-second-text">Hourly rate + $  </div>
              </div>
              <input type="number" class="form-control form-control-sm" placeholder="Amount" name="shiftDifferentialRate_HOURLY_ADD_amount" [(ngModel)]="shiftDifferentialRate.hourly" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="input-group input-group-sm mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text input-group-text-radio">
                  <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_PERCENT" value="HOURLY_PERCENT" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                </div>
                <div class="input-group-text input-group-second-text">Hourly rate + % </div>
              </div>
              <input type="number" class="form-control form-control-sm" placeholder="Percentage" name="shiftDifferentialRate_HOURLY_PERCENT_amount" [(ngModel)]="shiftDifferentialRate.percent" />
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="input-group input-group-sm mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text input-group-text-radio">
                  <input type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_BONUS_AMOUNT" value="BONUS" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>
                </div>
                <div class="input-group-text input-group-second-text">Bonus Amount: </div>
              </div>
              <input type="number" placeholder="Amount" class="form-control form-control-sm" name="shiftDifferentialRate_BONUS_AMOUNT_amount" [(ngModel)]="shiftDifferentialRate.bonus" />
            </div>
          </div>
        </div>

        <!--                                <div class="form-row">-->
        <!--                                  <div class="col">-->
        <!--&lt;!&ndash;                                    <div class="form-check form-check-inline">&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_ADD" value="HOURLY_ADD" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type"/>&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_HOURLY_ADD">Hourly rate + $</label>&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_HOURLY_ADD" id="shiftDifferentialRate_HOURLY_ADD_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />&ndash;&gt;-->
        <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
        <!--&lt;!&ndash;                                    <div class="form-check form-check-inline">&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_HOURLY_PERCENT" value="HOURLY_PERCENT" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type" />&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_HOURLY_ADD">Hourly rate + %</label>&ndash;&gt;-->
        <!--&lt;!&ndash;                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_HOURLY_PERCENT" id="shiftDifferentialRate_HOURLY_PERCENT_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />&ndash;&gt;-->
        <!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
        <!--                                    <div class="form-check form-check-inline">-->
        <!--                                      <input class="form-check-input form-control-sm" type="radio" name="shiftDifferentialRate" id="shiftDifferentialRate_BONUS_AMOUNT" value="BONUS" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.type" />-->
        <!--                                      <label class="form-check-label col-form-label-sm" for="shiftDifferentialRate_BONUS_AMOUNT">Bonus Amount: </label>-->
        <!--                                      <input class="form-check-input form-control-sm" type="number" name="shiftDifferentialRate_BONUS_AMOUNT" id="shiftDifferentialRate_BONUS_AMOUNT_amount" [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialRate.amount" />-->
        <!--                                    </div>-->
        <!--                                  </div>-->
        <!--                                </div>-->
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label-sm text-right">Please explain anything else you’d like us to know regarding shift differential pay:</label>
      <div class="col-sm-9">
        <input type="text" class="form-control form-control-sm" id="shiftDifferentialNotes" placeholder="Explain here"  [(ngModel)]="hrPayrollForm.compPayInfo.shiftDifferentialNotes" name="shiftDifferentialNotes" />
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have any employees who work for commission and/or tips?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="workForCommission" id="workForCommissionYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.workForCommission"/>
            <label class="form-check-label col-form-label-sm" for="workForCommissionYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="workForCommissionDesc" placeholder="If yes, explain"  [(ngModel)]="hrPayrollForm.compPayInfo.workForCommissionDescription" name="workForCommissionDesc" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="workForCommission" id="workForCommissionNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.workForCommission" />
            <label class="form-check-label col-form-label-sm" for="workForCommissionNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do employees receive paid breaks and/or lunches?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="paidBreaks" id="paidBreaksYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaks"/>
            <label class="form-check-label col-form-label-sm" for="paidBreaksYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="paidBreaksDesc" placeholder="If yes, explain"  [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaksNotes" name="paidBreaksDesc" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="paidBreaks" id="paidBreaksNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.paidBreaks" />
            <label class="form-check-label col-form-label-sm" for="paidBreaksNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have any employees who work fully remote?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="fullRemoteExistance" id="fullRemoteExistanceYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.fullRemoteExistance"/>
            <label class="form-check-label col-form-label-sm" for="fullRemoteExistanceYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="stateRemoteWorkIn" placeholder="If yes, who and please describe the arrangement"  [(ngModel)]="hrPayrollForm.compPayInfo.stateRemoteWorkIn" name="fullRemoteExistanceDesc" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="fullRemoteExistance" id="fullRemoteExistanceNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.fullRemoteExistance" />
            <label class="form-check-label col-form-label-sm" for="fullRemoteExistanceNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What Payroll/timekeeping system do you currently use?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="currentPayrollSystem" placeholder="Enter Payroll/timekeeping system"  [(ngModel)]="hrPayrollForm.compPayInfo.currentPayrollSystem" name="currentPayrollSystem" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your regular pay cycle?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="regularPayCycle" id="regularPayCycleWeekly" value="WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.regularPayCycle"/>
            <label class="form-check-label col-form-label-sm" for="regularPayCycleWeekly">Weekly</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="regularPayCycle" id="regularPayCycleBiWeekly" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.regularPayCycle" />
            <label class="form-check-label col-form-label-sm" for="regularPayCycleBiWeekly">Bi-weekly</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is the first day of your current pay cycle?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" *ngFor="let weekDay of weekDays; let x=index">
            <input class="form-check-input form-control-sm" type="radio" id="firstDayOfPayCycle_{{x}}" [value]="weekDay.id" [(ngModel)]="hrPayrollForm.compPayInfo.firstDayOfPayCycle"/>
            <label class="form-check-label col-form-label-sm" for="firstDayOfPayCycle_{{x}}">{{weekDay.value}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is the last day of your current pay cycle?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" *ngFor="let weekDay of weekDays; let j=index">
            <input class="form-check-input form-control-sm" type="radio" name="" id="lastDayOfPayCycle_{{j}}" [value]="weekDay.id" [(ngModel)]="hrPayrollForm.compPayInfo.lastDayOfPayCycle" />
            <label class="form-check-label col-form-label-sm" [for]="'lastDayOfPayCycle_'+j">{{weekDay.value}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are benefit deductions (medical, dental, vision, etc.) taken:</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsBiWeekly" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions"/>
            <label class="form-check-label col-form-label-sm" for="benefitDeductionsBiWeekly">Bi-weekly</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsTwiceMonth" value="BI_WEEKLY" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions" />
            <label class="form-check-label col-form-label-sm" for="benefitDeductionsTwiceMonth">Twice a month</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="benefitDeductions" id="benefitDeductionsNA" value="NA" [(ngModel)]="hrPayrollForm.compPayInfo.benefitDeductions" />
            <label class="form-check-label col-form-label-sm" for="benefitDeductionsNA">N/A</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you allow employees to deduct funds out of their paycheck (bi-weekly) and add
      to an account, as a savings, for services at the hospital?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="petBenefit" id="petBenefitYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.petBenefit"/>
            <label class="form-check-label col-form-label-sm" for="petBenefitYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="petBenefitNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.petBenefitNote" name="petBenefitDesc" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="petBenefit" id="petBenefitNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.petBenefit" />
            <label class="form-check-label col-form-label-sm" for="petBenefitNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do any employees receive any monthly allowances such as cell phone or auto
      allowances?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="monthlyAllowances" id="monthlyAllowancesYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowances"/>
            <label class="form-check-label col-form-label-sm" for="monthlyAllowancesYes">Yes&nbsp;</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="monthlyAllowances" id="monthlyAllowancesNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowances" />
            <label class="form-check-label col-form-label-sm" for="monthlyAllowancesNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, please describe the type of allowance, the amount of the allowance and who
      receives the allowance.</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="monthlyAllowancesNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.monthlyAllowancesNote" name="monthlyAllowancesNote" />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any other benefits (other than the standard medical, dental, vision, 401k)
      you offer for your employees such as employee discounts?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline" style="width:70%">
            <input class="form-check-input form-control-sm" type="radio" name="otherBenefits" id="otherBenefitsYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefits"/>
            <label class="form-check-label col-form-label-sm" for="otherBenefitsYes">Yes&nbsp;</label>
            <input type="text" class="form-control form-control-sm" id="otherBenefitsNote" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefitsNote" name="otherBenefitsDesc" />
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="otherBenefits" id="otherBenefitsNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.otherBenefits" />
            <label class="form-check-label col-form-label-sm" for="otherBenefitsNo">No</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <label class="col-sm-12 col-form-label-sm row ">
    What are your standard paid holidays and dates they are observed? For example:
    <ul>
      <li>New Year’s Day  (1/1) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('New Year’s Day  (1/1)')">add</button></li>
      <li>Labor Day (9/5) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Labor Day (9/5)')">add</button></li>
      <li>Memorial Day (5/30) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Memorial Day (5/30)')">add</button></li>
      <li>July 4 (7/4) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('July 4 (7/4)')">add</button></li>
      <li>Thanksgiving Day (11/24) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Thanksgiving Day (11/24)')">add</button></li>
      <li>Post-Thanksgiving (11/25) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Post-Thanksgiving (11/25)')">add</button></li>
      <li>Christmas eve (12/24) Christmas Day (falls on a Sunday when we are closed, so we will honor it on Monday 12/26) <button class="btn btn-xs btn-outline-primary" (click)="addHolidayToList('Christmas eve (12/24)')">add</button></li>
    </ul>
  </label>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Holiday List</label>
    <div class="col-sm-9">
      <div *ngFor="let holiday of hrPayrollForm.compPayInfo.holidayList; let i=index">
        <div class="form-row" style="margin-top:5px">
          <div class="col">
            <input type="text" class="form-control form-control-sm" placeholder="Holiday description" name = "holiday-{{i}}" [(ngModel)]="hrPayrollForm.compPayInfo.holidayList[i]"/>
          </div>
          <div class="col">
            <button type="submit" (click)="removeHolidayToList(holiday)" class="btn btn-outline-danger btn-sm">&nbsp;delete&nbsp;</button>
          </div>
        </div>
      </div>
      <button style="margin-top:5px" type="submit" (click)="addHolidayToList('')" class="btn btn-outline-primary btn-sm">&nbsp;add&nbsp;</button>
    </div>
  </div>


  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is a standard holiday between the hours of 12:00am to 12:00pm?</label>
    <div class="col-sm-9">
      <div class="form-row">
        <div class="col">
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="standardHolidayHours" id="standardHolidayHoursYes" value="YES" [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHours"/>
            <label class="form-check-label col-form-label-sm" for="standardHolidayHoursYes">Yes&nbsp;</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input form-control-sm" type="radio" name="standardHolidayHours" id="standardHolidayHoursNo" value="NO" [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHours" />
            <label class="form-check-label col-form-label-sm" for="standardHolidayHoursNo">No</label>
            <input type="text" class="form-control form-control-sm" id="standardHolidayHoursNote" placeholder="If no, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayHoursNote" name="standardHolidayHoursNote" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How many hours do employees receive for standard holiday pay?</label>
    <div class="col-sm-9">
      <input type="number" min="0" class="form-control form-control-sm" id="standardHolidayPayHours" placeholder="If yes, please explain"  [(ngModel)]="hrPayrollForm.compPayInfo.standardHolidayPayHours" name="standardHolidayPayHours" />
    </div>
  </div>
</div>

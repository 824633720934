import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'marketing-form-advertising',
  templateUrl: './marketing-form-advertising.component.html',
  styleUrls: ['./marketing-form-advertising.component.css']
})
export class MarketingFormAdvertisingComponent implements OnInit {
  @Input() marketingForm: any;
  constructor() { }

  ngOnInit(): void {
  }

}

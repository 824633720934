
<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">COMPENSATION &amp; PTO INFORMATION</div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your Vacation / PTO Policy?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vacationPtoPolicy" [(ngModel)]="compAndPtoInfo.vacationPtoPolicy"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your Sick Time Policy?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_sickTimePolicy" [(ngModel)]="compAndPtoInfo.sickTimePolicy"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How is vacation calculated? If granted on a date, what is the date?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vacationCalculationAndDate" [(ngModel)]="compAndPtoInfo.vacationCalculationAndDate"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Does anyone in the hospital receive a monthly cell phone allowance?<br/>If yes, who and how much?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_cellPhoneAllowance" [(ngModel)]="compAndPtoInfo.cellPhoneAllowance"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any special employee arrangements we should be aware of?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialEmployeeArrangements" [(ngModel)]="compAndPtoInfo.specialEmployeeArrangements"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any special grandfathered arrangements we should be made aware of?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_specialGrandfatheredArrangements" [(ngModel)]="compAndPtoInfo.specialGrandfatheredArrangements"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any staff excluding Vets on Pro Sal Basis?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vetsOnProSalBasisExclusion" [(ngModel)]="compAndPtoInfo.vetsOnProSalBasisExclusion"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How often is pro-Sal compensation calculated?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_proSalCompensationCalcPeriod" [(ngModel)]="compAndPtoInfo.proSalCompensationCalcPeriod"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Provide formula for calculating Pro-Sal compensation</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_proSalCompensationCalcFormula" [(ngModel)]="compAndPtoInfo.proSalCompensationCalcFormula"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are there any Employees on a bonus Structure?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_bonusStructureEmployees" [(ngModel)]="compAndPtoInfo.bonusStructureEmployees"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide bonuses at any specific time during the year?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_hasBonusesAtSpecificDate" [(ngModel)]="compAndPtoInfo.hasBonusesAtSpecificDate"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">If yes, what are bonuses based on?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_bonusesBase" [(ngModel)]="compAndPtoInfo.bonusesBase"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How are vets compensated?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_vetCompensation" [(ngModel)]="compAndPtoInfo.vetCompensation"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide offer letters to staff, excluding vets, upon hire?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_offerLettersToStaff" [(ngModel)]="compAndPtoInfo.offerLettersToStaff"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you require contracts with your Veterinarians?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_contractsWithVeterinarians" [(ngModel)]="compAndPtoInfo.contractsWithVeterinarians"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are raises provided to staff?<br/>If so, how often?<br/>If annually, when is it provided?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_providedRaisesFreqAndWhen" [(ngModel)]="compAndPtoInfo.providedRaisesFreqAndWhen"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">When were the last raises given?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="compandptoinfo_lastRaisesDate" [(ngModel)]="compAndPtoInfo.lastRaisesDate"/>
    </div>
  </div>
</div>

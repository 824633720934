<div class="alert alert-primary" role="alert" style="text-align:center;">General Information</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="contact" placeholder="Enter marketing contact"  [(ngModel)]="marketingForm.generalInfo.contact" name="contact" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact E-mail</label>
  <div class="col-sm-4">
    <input type="text" class="form-control form-control-sm" id="email" placeholder="Enter email"  [(ngModel)]="marketingForm.generalInfo.email" name="email" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Marketing Contact phone</label>
  <div class="col-sm-4">
    <input type="tel" class="form-control form-control-sm" id="phone" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" [(ngModel)]="marketingForm.generalInfo.phone" name="phone" />
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {FSAProviderPlanNumber, HrPlanInfo, ProviderNamePlanNumber} from "../hrform.service";

@Component({
  selector: 'hrform-plan-info',
  templateUrl: './hr-form-plan-info.component.html',
  styleUrls: ['./hr-form-plan-info.component.css']
})
export class HrFormPlanInfoComponent implements OnInit {
  @Input()  planInfo: HrPlanInfo;
  constructor() { }

  ngOnInit(): void {
  }

  onNAChange($event, item: ProviderNamePlanNumber) {
    if($event.target.checked) {
      item.notAvailable = true;
    } else {
      item.notAvailable = false;
    }
  }
  onFSANAChange($event, item: FSAProviderPlanNumber) {
    if($event.target.checked) {
      item.notAvailable = true;
    } else {
      item.notAvailable = false;
    }
  }
}

<div class="container col-lg-6">
  <div class="row style-filler-row"></div>
  <div class="col-lg-12">
    <a href="https://rarebreedvet.com/"><img class="img-responsive style-login-logo" alt="Rarebreed Veterinary Partners"
                                             src="assets/images/rarebreed_logo.png"></a>
    <br/>
    <p class="style-center-align">Rarebreed is a multi-site owner, operator, and business partner to veterinarians in
      the United States.</p>
    <hr/>
  </div>
  <div class="col-lg-6 style-center-align">
    <div *ngIf="!otherUsersLogin">
      <p class="style-center-align" style="padding-top: 10px">Rarebreed Employees.</p><br/>
      <button (click)="loginMs()" class="btn btn-primary style-center-align">Sign in with Office 365</button>
      <hr style="margin-top: 25px"/>
      <p class="style-center-align" style="padding-top: 10px">All other users please login here.</p><br/>
      <button (click)="otherUsersLogin = true" class="btn btn-primary style-center-align">Login</button>
    </div>

    <form *ngIf="otherUsersLogin" class="form-group">
      <div class="alert alert-warning" *ngIf='invalidLogin'>{{errorMessage}}</div>
      <div class="alert alert-success" *ngIf='loginSuccess'>{{successMessage}}</div>
      <div class="form-group">
        <input type="text" class="form-control control" id="username" [(ngModel)]="username" placeholder="User Name"
               name="username">
      </div>
      <div class="form-group">
        <input type="password" class="form-control control" [(ngModel)]="password" id="password" placeholder="Password"
               name="password">
      </div>
      <div class="btn-group">
        <button (click)="otherUsersLogin = false" class="btn btn-secondary style-center-align control">Back</button>
        <button (click)="login()" class="btn btn-primary style-center-align control">Sign in</button>
      </div>
    </form>
  </div>
  <div class="row style-filler-row"></div>
</div>
<footer class="text-center text-lg-start text-muted">
  <div class="text-center p-4">
    Copyright &copy; 2020-{{currentYear}}
  </div>
</footer>

<app-menu></app-menu>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':spinnerModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content style-modal-spinner">
             <div class="text-center">
                <div class="spinner-border style-modal-spinner-dim" role="status">
                </div>
              </div>
          </div>
     </div>
</div>

<div class="modal" tabindex="-1" role="dialog"  [ngStyle]="{'display':infoModalDisplay}">
     <div class="modal-dialog modal-dialog-centered" role="document">
           <div class="modal-content">
                 <div class="modal-header">
                     <h5 class="modal-title">{{infoModalTitle}}</h5>
                     <button type="button" class="close" aria-label="Close" (click)="hideInfoModalDisplay()"><span aria-hidden="true">&times;</span></button>
                </div>
                <div class="modal-body">
                    <p>{{infoModalMessage}}</p>
                </div>
                <div class="modal-footer">
                    <br/>
                </div>
          </div>
     </div>
</div>

<div class="container mw-100 h-100">
    <div class="row h-100">
        <div class="col col-lg-2 bg-light">
            <app-usermenu></app-usermenu>
        </div>

        <div class="col col-lg-7">
          <h6 style="padding: 2px;"><a routerLink="/editproject" [queryParams]="{id: projectId}"><span class="bi bi-arrow-left"></span> Back to project</a></h6>
              <div [hidden]="!loaded" class="d-flex flex-row-reverse">
                <div class="p-2 font-weight-light"><small>Last updated on {{marketingForm?.lastSavedOn | date : 'MM-dd-yyyy hh:mm:ss a'}}</small></div>
              </div>
              <h5 class="card-title">Marketing Questionnaire&nbsp;<small>{{projectName}}</small></h5>
              <div class="card" *ngIf="loaded">
                  <div class="card-body">
                      <h6 class="card-title font-weight-normal">Marketing</h6>
                      <form class="form-group">
                        <marketing-form-general [marketingForm]="marketingForm"></marketing-form-general>
                        <br/>
                        <marketing-form-internal [marketingForm]="marketingForm"></marketing-form-internal>
                        <br/>
                        <marketing-form-website [marketingForm]="marketingForm" [validationFields]="validationFields"></marketing-form-website>
                        <br/>
                        <marketing-form-social [marketingForm]="marketingForm"></marketing-form-social>
                        <br/>
                        <marketing-form-advertising [marketingForm]="marketingForm"></marketing-form-advertising>
                        <br/>
                        <marketing-form-sponsor [marketingForm]="marketingForm"></marketing-form-sponsor>
                        <br/>
                        <marketing-form-referral [marketingForm]="marketingForm"></marketing-form-referral>

                          <div class="btn-group float-right mt-2" role="group">
                            <button (click)="prevForm()" class="btn btn-outline-primary">&nbsp;&lt;&lt;&nbsp;</button>
                            <button *ngIf="marketingForm.status=='IN_PROGRESS'" type="submit" [disabled]="validationFields.length>0" (click)="save()" class="btn btn-primary">Save</button>
                            <button *ngIf="marketingForm.status=='IN_PROGRESS'" (click)="finish()" class="btn btn-outline-primary">Finish</button>
                          </div>
                      </form>
                  </div>
              </div>
            <br/>
        </div>
    </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {HrEmployeeInfo, HrPlanCostInfo} from "../hrform.service";

@Component({
  selector: 'hrform-employee-info',
  templateUrl: './hr-form-employee-info.component.html',
  styleUrls: ['./hr-form-employee-info.component.css']
})
export class HrFormEmployeeInfoComponent implements OnInit {
  @Input() employeeInfo: HrEmployeeInfo;
  constructor() { }

  ngOnInit(): void {
  }

}

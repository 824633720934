<div class="alert alert-primary" role="alert" style="text-align:center;">Internal Marketing</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a company logo?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveCompanyLogo" id="haveCompanyLogoYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveCompanyLogo"/>
          <label class="form-check-label col-form-label-sm" for="haveCompanyLogoYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveCompanyLogo" id="haveCompanyLogoNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveCompanyLogo" />
          <label class="form-check-label col-form-label-sm" for="haveCompanyLogoNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a high-resolution copy?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveHighResCopy" id="haveHighResCopyYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveHighResCopy"/>
          <label class="form-check-label col-form-label-sm" for="haveHighResCopyYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveHighResCopy" id="haveHighResCopyNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveHighResCopy" />
          <label class="form-check-label col-form-label-sm" for="haveHighResCopyNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have company e-mails?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveCompanyEmails" id="haveCompanyEmailsYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveCompanyEmails"/>
          <label class="form-check-label col-form-label-sm" for="haveCompanyEmailsYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveCompanyEmails" id="haveCompanyEmailsNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveCompanyEmails" />
          <label class="form-check-label col-form-label-sm" for="haveCompanyEmailsNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, what is the domain?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="domain" placeholder="Enter email domain" [(ngModel)]="marketingForm.internalInfo.domain" name="domain" />
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, are there any general mail boxes currently in use at the hospital?  i.e. contact &#64;yourdomain.com or admin&#64;yourdomain.com?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="mailbox" placeholder="Enter general email" [(ngModel)]="marketingForm.internalInfo.generalEmail" name="generalEmail" />
  </div>
</div>


<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Does every staff member have e-mail?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveEmailPerStaff" id="haveEmailPerStaffYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveEmailPerStaff"/>
          <label class="form-check-label col-form-label-sm" for="haveEmailPerStaffYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveEmailPerStaff" id="haveEmailPerStaffNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveEmailPerStaff" />
          <label class="form-check-label col-form-label-sm" for="haveEmailPerStaffNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If no, who does have company email?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="companyEmailStaffList" placeholder="Enter names separated by comma" [(ngModel)]="marketingForm.internalInfo.companyEmailStaffList" name="companyEmailStaffList" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you produce a monthly e-newsletter?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="generateMonthlyNewsLetter" id="generateMonthlyNewsLetterYes" value="YES" [(ngModel)]="marketingForm.internalInfo.generateMonthlyNewsLetter"/>
          <label class="form-check-label col-form-label-sm" for="generateMonthlyNewsLetterYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="generateMonthlyNewsLetter" id="generateMonthlyNewsLetterNo" value="NO" [(ngModel)]="marketingForm.internalInfo.generateMonthlyNewsLetter" />
          <label class="form-check-label col-form-label-sm" for="generateMonthlyNewsLetterNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, answer the following:</label>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who writes it and edits it?</label>
  <div class="col-sm-8">
    <input type="text" name="monthlyNewsLetterAuthor" class="form-control form-control-sm" placeholder="" [(ngModel)]="marketingForm.internalInfo.monthlyNewsLetterAuthor">
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">How is it distributed?</label>
  <div class="col-sm-8">
    <input type="text" name="monthlyNewsLetterDistMode" class="form-control form-control-sm" placeholder="" [(ngModel)]="marketingForm.internalInfo.monthlyNewsLetterDistMode">
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is the distribution cadence?</label>
  <div class="col-sm-8">
    <input type="text" name="monthlyNewsLetterDistCadence" class="form-control form-control-sm" placeholder=""  [(ngModel)]="marketingForm.internalInfo.monthlyNewsLetterDistCadence">
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a blog?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveBlog" id="haveBlogYes" value="YES" [(ngModel)]="marketingForm.internalInfo.haveBlog"/>
          <label class="form-check-label col-form-label-sm" for="haveBlogYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveBlog" id="haveBlogNo" value="NO" [(ngModel)]="marketingForm.internalInfo.haveBlog" />
          <label class="form-check-label col-form-label-sm" for="haveBlogNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, who writes it and where is it posted and what is the frequency of posting?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="blogAuthor" placeholder="Enter blog author name" [(ngModel)]="marketingForm.internalInfo.blogAuthor" name="blogAuthor" />
    <input type="text" class="form-control form-control-sm" id="blogDistMode" placeholder="Enter where blog is posted" [(ngModel)]="marketingForm.internalInfo.blogDistMode" name="blogDistMode" />
    <input type="text" class="form-control form-control-sm" id="blogFreq" placeholder="Enter frequency of posting" [(ngModel)]="marketingForm.internalInfo.blogFreq" name="blogFreq" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you offer any promotions or discounts?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="offerDiscount" id="offerDiscountYes" value="YES" [(ngModel)]="marketingForm.internalInfo.offerDiscount"/>
          <label class="form-check-label col-form-label-sm" for="offerDiscountYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="offerDiscount" id="offerDiscountNo" value="NO" [(ngModel)]="marketingForm.internalInfo.offerDiscount" />
          <label class="form-check-label col-form-label-sm" for="offerDiscountNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, check all that apply</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_dollar" value="PCT_DOLLAR_OFF" [checked]="marketingForm.internalInfo.discountTypes.indexOf('PCT_DOLLAR_OFF') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_dollar">% or $ off</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_cpns" value="COUPONS" [checked]="marketingForm.internalInfo.discountTypes.indexOf('COUPONS') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_cpns">Coupons</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_fitem" value="FREE_FIRST_ITEM" [checked]="marketingForm.internalInfo.discountTypes.indexOf('FREE_FIRST_ITEM') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_fitem">Free First Item</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_vday" value="VACCINE_DAY" [checked]="marketingForm.internalInfo.discountTypes.indexOf('VACCINE_DAY') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_vday">Vaccine Day</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_visit" value="VISIT" [checked]="marketingForm.internalInfo.discountTypes.indexOf('VISIT') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_visit">Discount on Products with Visit</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_dental" value="DENTAL_MONTH" [checked]="marketingForm.internalInfo.discountTypes.indexOf('DENTAL_MONTH') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_dental">Dental Month</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_nclient" value="NEW_CLIENT" [checked]="marketingForm.internalInfo.discountTypes.indexOf('NEW_CLIENT') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_nclient">New Client</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_mpet" value="MULTIPLE_PET" [checked]="marketingForm.internalInfo.discountTypes.indexOf('MULTIPLE_PET') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_mpet">Multiple Pet Discount</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_anniv" value="ANNIVERSARY" [checked]="marketingForm.internalInfo.discountTypes.indexOf('ANNIVERSARY') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_anniv">Anniversary Discount</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_puppy" value="PUPPY_PROMO" [checked]="marketingForm.internalInfo.discountTypes.indexOf('PUPPY_PROMO') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_puppy">Puppy Promo</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_pct_ft" value="FLEA_TICK" [checked]="marketingForm.internalInfo.discountTypes.indexOf('FLEA_TICK') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_pct_ft">Flea Tick Discount</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="checkbox" id="disc_other" value="OTHER" [checked]="marketingForm.internalInfo.discountTypes.indexOf('OTHER') > -1" (change)="onDiscountTypesCheckboxChange($event)" />
          <label class="form-check-label col-form-label-sm" for="disc_other">Other&nbsp;</label>
          <input type="text" class="form-control form-control-sm" id="discOtherDesc" placeholder="Please provide details"  name="discOtherDesc" [(ngModel)]="marketingForm.internalInfo.discountTypesDesc"/>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you produce client-facing marketing materials?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="produceClientFacingMaterial" id="produceClientFacingMaterialYes" value="YES" [(ngModel)]="marketingForm.internalInfo.produceClientFacingMaterial"/>
          <label class="form-check-label col-form-label-sm" for="produceClientFacingMaterialYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="produceClientFacingMaterial" id="produceClientFacingMaterialNo" value="NO" [(ngModel)]="marketingForm.internalInfo.produceClientFacingMaterial" />
          <label class="form-check-label col-form-label-sm" for="produceClientFacingMaterialNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, what is created?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="clientFacingMaterialDesc" placeholder="" [(ngModel)]="marketingForm.internalInfo.clientFacingMaterialDesc" name="clientFacingMaterialDesc" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Can you provide a copy to us?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="canProvideClientFacingMaterialCopy" id="canProvideClientFacingMaterialCopyYes" value="YES" [(ngModel)]="marketingForm.internalInfo.canProvideClientFacingMaterialCopy"/>
          <label class="form-check-label col-form-label-sm" for="canProvideClientFacingMaterialCopyYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="canProvideClientFacingMaterialCopy" id="canProvideClientFacingMaterialCopyNo" value="NO" [(ngModel)]="marketingForm.internalInfo.canProvideClientFacingMaterialCopy" />
          <label class="form-check-label col-form-label-sm" for="canProvideClientFacingMaterialCopyNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

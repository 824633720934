import {Component, Input, OnInit} from '@angular/core';
import {MarketingformService} from "../marketingform.service";
import {YesNo} from "../../hrform/hrform.service";

@Component({
  selector: 'marketing-form-referral',
  templateUrl: './marketing-form-referral.component.html',
  styleUrls: ['./marketing-form-referral.component.css']
})
export class MarketingFormReferralComponent implements OnInit {
  @Input() marketingForm: any;
  private isGeneral: boolean = false;
  constructor() { }

  ngOnInit(): void {

  }


  onCheckGeneralNA($event) {
    this.marketingForm.referralInfo.isGeneralPractice = $event.target.checked;
  }


  onMarketingEventTypesCheckboxChange($event) {
    MarketingformService.onCheckBoxEvent(this.marketingForm.referralInfo.marketingEventTypes, $event);
  }
}

<br/>
<div class="bg-light border-all" id="sidebar-wrapper">
   <div class="list-group list-group-flush">
      <a [routerLink]="showDueDiligenceForm()" routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}" style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">Due Diligence</a>
      <a [routerLink]="showDeaForm()"  routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}"  style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">DEA</a>
<!--      <a [routerLink]="showHrForm()"  routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}"  style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">People Ops, Benefits and Payroll</a>-->
      <a [routerLink]="showNHRForm()"  routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}"  style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">People Ops, Benefits and Payroll</a>
      <a [routerLink]="showItForm()"  routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}"  style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">IT</a>
      <a [routerLink]="showMarketingForm()"  routerLinkActive="activated" [queryParams]="{onBoardingProjectId: onBoardingProjectId}"  style="cursor:pointer;" class="list-group-item list-group-item-action bg-light">Marketing</a>
    </div>
  </div>

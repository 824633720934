<div class="alert alert-primary" role="alert" style="text-align:center;">Social Media/Digital Marketing</div>
<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a Facebook account?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveFacebookAccount" id="haveFacebookAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveFacebookAccount"/>
          <label class="form-check-label col-form-label-sm" for="haveFacebookAccountYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveFacebookAccount" id="haveFacebookAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveFacebookAccount" />
          <label class="form-check-label col-form-label-sm" for="haveFacebookAccountNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, please answer the following:</label>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is the account name?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="facebookAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.facebookAccountName" name="facebookAccountName" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who manages it?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="facebookAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.facebookAuthor" name="facebookAuthor" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who is listed as the admin and has current credentials?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="facebookAdmin" placeholder="" [(ngModel)]="marketingForm.socialInfo.facebookAdmin" name="facebookAdmin" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you regularly share content?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareFacebookContent" id="shareFacebookContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareFacebookContent"/>
          <label class="form-check-label col-form-label-sm" for="shareFacebookContentYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareFacebookContent" id="shareFacebookContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareFacebookContent" />
          <label class="form-check-label col-form-label-sm" for="shareFacebookContentNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you advertise on Facebook?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnFacebook" id="advertiseOnFacebookYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnFacebook"/>
          <label class="form-check-label col-form-label-sm" for="advertiseOnFacebookYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnFacebook" id="advertiseOnFacebookNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnFacebook" />
          <label class="form-check-label col-form-label-sm" for="advertiseOnFacebookNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have an Instagram account?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveInstagramAccount" id="haveInstagramAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveInstagramAccount"/>
          <label class="form-check-label col-form-label-sm" for="haveInstagramAccountYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveInstagramAccount" id="haveInstagramAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveInstagramAccount" />
          <label class="form-check-label col-form-label-sm" for="haveInstagramAccountNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, please answer the following:</label>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is the account name?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="instagramAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.instagramAccountName" name="instagramAccountName" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who manages it?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="instagramAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.instagramAuthor" name="instagramAuthor" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who is listed as the admin and has current credentials?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="instagramAdmin" placeholder="" [(ngModel)]="marketingForm.socialInfo.instagramAdmin" name="instagramAdmin" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you regularly share content?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareInstagramContent" id="shareInstagramContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareInstagramContent"/>
          <label class="form-check-label col-form-label-sm" for="shareInstagramContentYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareInstagramContent" id="shareInstagramContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareInstagramContent" />
          <label class="form-check-label col-form-label-sm" for="shareInstagramContentNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you advertise on Instagram?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnInstagram" id="advertiseOnInstagramYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnInstagram"/>
          <label class="form-check-label col-form-label-sm" for="advertiseOnInstagramYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnInstagram" id="advertiseOnInstagramNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnInstagram" />
          <label class="form-check-label col-form-label-sm" for="advertiseOnInstagramNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a Twitter account?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveTwitterAccount" id="haveTwitterAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveTwitterAccount"/>
          <label class="form-check-label col-form-label-sm" for="haveTwitterAccountYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveTwitterAccount" id="haveTwitterAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveTwitterAccount" />
          <label class="form-check-label col-form-label-sm" for="haveTwitterAccountNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, please answer the following:</label>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is the account name?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="twitterAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.twitterAccountName" name="twitterAccountName" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who manages it?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="twitterAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.twitterAuthor" name="twitterAuthor" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who is listed as the admin and has current credentials?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="twitterAdmin" placeholder="" [(ngModel)]="marketingForm.socialInfo.twitterAdmin" name="twitterAdmin" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you regularly share content?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareTwitterContent" id="shareTwitterContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.shareTwitterContent"/>
          <label class="form-check-label col-form-label-sm" for="shareTwitterContentYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareTwitterContent" id="shareTwitterContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.shareTwitterContent" />
          <label class="form-check-label col-form-label-sm" for="shareTwitterContentNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you advertise on Twitter?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnTwitter" id="advertiseOnTwitterYes" value="YES" [(ngModel)]="marketingForm.socialInfo.advertiseOnTwitter"/>
          <label class="form-check-label col-form-label-sm" for="advertiseOnTwitterYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnTwitter" id="advertiseOnTwitterNo" value="NO" [(ngModel)]="marketingForm.socialInfo.advertiseOnTwitter" />
          <label class="form-check-label col-form-label-sm" for="advertiseOnTwitterNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you regularly promote offers on social media?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="promoteOfferOnSocialMedia" id="promoteOfferOnSocialMediaYes" value="YES" [(ngModel)]="marketingForm.socialInfo.promoteOfferOnSocialMedia"/>
          <label class="form-check-label col-form-label-sm" for="promoteOfferOnSocialMediaYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="promoteOfferOnSocialMedia" id="promoteOfferOnSocialMediaNo" value="NO" [(ngModel)]="marketingForm.socialInfo.promoteOfferOnSocialMedia" />
          <label class="form-check-label col-form-label-sm" for="promoteOfferOnSocialMediaNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you direct website visitors to social media with links?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="directSiteVisitorToSocialMedia" id="directSiteVisitorToSocialMediaYes" value="YES" [(ngModel)]="marketingForm.socialInfo.directSiteVisitorToSocialMedia"/>
          <label class="form-check-label col-form-label-sm" for="directSiteVisitorToSocialMediaYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="directSiteVisitorToSocialMedia" id="directSiteVisitorToSocialMediaNo" value="NO" [(ngModel)]="marketingForm.socialInfo.directSiteVisitorToSocialMedia" />
          <label class="form-check-label col-form-label-sm" for="directSiteVisitorToSocialMediaNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a LinkedIn account?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveLinkedinAccount" id="haveLinkedinAccountYes" value="YES" [(ngModel)]="marketingForm.socialInfo.haveLinkedinAccount"/>
          <label class="form-check-label col-form-label-sm" for="haveLinkedinAccountYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveLinkedinAccount" id="haveLinkedinAccountNo" value="NO" [(ngModel)]="marketingForm.socialInfo.haveLinkedinAccount" />
          <label class="form-check-label col-form-label-sm" for="haveLinkedinAccountNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, please answer the following:</label>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is the account name or names?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="linkedinAccountName" placeholder="" [(ngModel)]="marketingForm.socialInfo.linkedinAccountName" name="linkedinAccountName" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who manages it/them?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="linkedinAuthor" placeholder="" [(ngModel)]="marketingForm.socialInfo.linkedinAuthor" name="linkedinAuthor" />
  </div>
</div>
<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who is listed as the admin and has current credentials?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="linkedinAdmin" placeholder="" [(ngModel)]="marketingForm.socialInfo.linkedinAdmin" name="linkedinAdmin" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you regularly share content?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareLinkedinContent" id="shareLinkedinContentYes" value="YES" [(ngModel)]="marketingForm.socialInfo.linkedinShareContent"/>
          <label class="form-check-label col-form-label-sm" for="shareLinkedinContentYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="shareLinkedinContent" id="shareLinkedinContentNo" value="NO" [(ngModel)]="marketingForm.socialInfo.linkedinShareContent" />
          <label class="form-check-label col-form-label-sm" for="shareLinkedinContentNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Do you advertise on Linkedin?</label>
  <div class="col-sm-8">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnLinkedin" id="advertiseOnLinkedinYes" value="YES" [(ngModel)]="marketingForm.socialInfo.linkedinAdvertise"/>
          <label class="form-check-label col-form-label-sm" for="advertiseOnLinkedinYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="advertiseOnLinkedin" id="advertiseOnLinkedinNo" value="NO" [(ngModel)]="marketingForm.socialInfo.linkedinAdvertise" />
          <label class="form-check-label col-form-label-sm" for="advertiseOnLinkedinNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have a presence on any other social media account?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline" style="width:70%">
          <input class="form-check-input form-control-sm" type="radio" name="additionalSocial" id="additionalSocialYes" value="YES" [(ngModel)]="marketingForm.socialInfo.additionalSocial"/>
          <label class="form-check-label col-form-label-sm" for="additionalSocialYes">Yes&nbsp;</label>
          <input type="text" class="form-control form-control-sm" id="additionalSocialList" placeholder="If so please list."  [(ngModel)]="marketingForm.socialInfo.additionalSocialList" name="additionalSocialList" />
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="additionalSocial" id="additionalSocialNo" value="NO" [(ngModel)]="marketingForm.socialInfo.additionalSocial" />
          <label class="form-check-label col-form-label-sm" for="additionalSocialNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If you advertise on social media, how do you measure ROI on your ads?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="roiMeasurement" placeholder="" [(ngModel)]="marketingForm.socialInfo.roiMeasurement" name="roiMeasurement" />
  </div>
</div>
<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">How do you determine what platform to advertise on?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="advertisePlatformDetermination" placeholder="" [(ngModel)]="marketingForm.socialInfo.advertisePlatformDetermination" name="advertisePlatformDetermination" />
  </div>
</div>

<div>
  <div class="alert alert-primary" role="alert" style="text-align:center;">EMPLOYEE INFORMATION</div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you provide annual employee reviews? If yes, who provides reviews?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_annualReviews" [(ngModel)]="employeeInfo.annualReviews"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is there documentation of review in employee file?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_documentationInEmployeeFile" [(ngModel)]="employeeInfo.documentationInEmployeeFile"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who are your key employees, managers or supervisors?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_keyEmployeesManagers" [(ngModel)]="employeeInfo.keyEmployeesManagers"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What shifts do employees typically work?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_typicalShifts" [(ngModel)]="employeeInfo.typicalShifts"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Are you currently hiring any positions? If yes what positions and how many openings?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringPositionsAmount" [(ngModel)]="employeeInfo.hiringPositionsAmount"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How long has each position been posted?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringPositionsPostDuration" [(ngModel)]="employeeInfo.hiringPositionsPostDuration"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you have any contracts with any recruiting agencies?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_contractsWithHiringAgencies" [(ngModel)]="employeeInfo.contractsWithHiringAgencies"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">What is your hiring budget per position?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringBudgetPerPostion" [(ngModel)]="employeeInfo.hiringBudgetPerPostion"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How long does it take you typically fill your Vet positions?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_typicalDurationToFillVetPostion" [(ngModel)]="employeeInfo.typicalDurationToFillVetPostion"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">How about your Licensed Tech positions?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_licensedTechPostions" [(ngModel)]="employeeInfo.licensedTechPostions"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Who does the hiring?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_hiringResponsible" [(ngModel)]="employeeInfo.hiringResponsible"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do any employees have a company credit card? If yes, list who currently has credit cards?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_companyCreditCardHolders" [(ngModel)]="employeeInfo.companyCreditCardHolders"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Is there a Union or Collective Bargaining Agreement in place?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_bargainingAgreement" [(ngModel)]="employeeInfo.bargainingAgreement"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you offer Tuition Reimbursement or Student Loan Repayment?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_tuitionReimbursement" [(ngModel)]="employeeInfo.tuitionReimbursement"/>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label-sm text-right">Do you offer pet insurance or other pet benefits to employees?</label>
    <div class="col-sm-9">
      <input type="text" class="form-control form-control-sm" id="employeeinfo_petInsuranceOrBenefit" [(ngModel)]="employeeInfo.petInsuranceOrBenefit"/>
    </div>
  </div>
</div>

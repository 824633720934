import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hrform-notes',
  templateUrl: './hr-form-notes.component.html',
  styleUrls: ['./hr-form-notes.component.css']
})
export class HrFormNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {HrLeaveInfo, LeaveEmployee} from "../hrform.service";

@Component({
  selector: 'hrform-leave-info',
  templateUrl: './hr-form-leave-info.component.html',
  styleUrls: ['./hr-form-leave-info.component.css']
})
export class HrFormLeaveInfoComponent implements OnInit {

  @Input() leaveInfo: HrLeaveInfo;
  constructor() { }

  ngOnInit(): void {
  }
  addEmployeeToList(){
    let emp = {name:'', lastDayOfWork:'', expectedReturnDate:''};
    this.leaveInfo.employeesOnLeave.push(emp);
  }
  removeEmployeeToList(index){
    this.leaveInfo.employeesOnLeave.splice(index, 1);
  }
}

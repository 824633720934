<div class="alert alert-primary" role="alert" style="text-align:center;">Advertising</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you have an annual Advertising budget?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveAnnualAdBudget" id="haveAnnualAdBudgetYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveAnnualAdBudget"/>
          <label class="form-check-label col-form-label-sm" for="haveAnnualAdBudgetYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveAnnualAdBudget" id="haveAnnualAdBudgetNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveAnnualAdBudget" />
          <label class="form-check-label col-form-label-sm" for="haveAnnualAdBudgetNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-12 col-form-label-sm">If yes, please answer the following:</label>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">What is your annual budget?</label>
  <div class="col-sm-4">
    <input type="number" min="0" class="form-control form-control-sm" id="annualBudgetValue" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.annualBudgetValue" name="annualBudgetValue" />
  </div>
</div>

<div class="form-group row">
  <label class="offset-sm-1 col-sm-3 col-form-label-sm text-right">Who tracks and manages the budget?</label>
  <div class="col-sm-8">
    <input type="text" class="form-control form-control-sm" id="budgetManagerContact" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.budgetManagerContact" name="budgetManagerContact" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">What media do you advertise with/on?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="adMedaList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.adMedaList" name="adMedaList" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">What metrics do you employ to measure success or ROI?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="roiCalcMethodList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.roiCalcMethodList" name="roiCalcMethodList" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Are you in any current Advertising contracts?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveAdContracts" id="haveAdContractsYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveAdContracts"/>
          <label class="form-check-label col-form-label-sm" for="haveAdContractsYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveAdContracts" id="haveAdContractsNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveAdContracts" />
          <label class="form-check-label col-form-label-sm" for="haveAdContractsNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Describe the contract type and general terms for each?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="adContractList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.adContractList" name="adContractList" />
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">Do you do seasonal advertising?</label>
  <div class="col-sm-9">
    <div class="form-row">
      <div class="col">
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveSeasonalAd" id="haveSeasonalAdYes" value="YES" [(ngModel)]="marketingForm.advertisingInfo.haveSeasonalAd"/>
          <label class="form-check-label col-form-label-sm" for="haveSeasonalAdYes">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input form-control-sm" type="radio" name="haveSeasonalAd" id="haveSeasonalAdNo" value="NO" [(ngModel)]="marketingForm.advertisingInfo.haveSeasonalAd" />
          <label class="form-check-label col-form-label-sm" for="haveSeasonalAdNo">No</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="form-group row">
  <label class="col-sm-3 col-form-label-sm text-right">If yes, what season and what advertising?</label>
  <div class="col-sm-9">
    <input type="text" class="form-control form-control-sm" id="seasonalAdList" placeholder="" [(ngModel)]="marketingForm.advertisingInfo.seasonalAdList" name="seasonalAdList" />
  </div>
</div>
